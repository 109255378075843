import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useForm } from "react-hook-form";
import { FiArrowLeft, FiUploadCloud, FiCheckCircle, FiXOctagon } from 'react-icons/fi'; 
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import axios from 'axios';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Modal from './FullModal';
import auth_API from '../services/api';
import { UserContext } from '../Contexts/UserContext';
import { ErrorContext } from '../Contexts/ErrorContext';
import ErrorModal from './ErrorModal';
import countryCodes from '../utils/countryCodes.json';

import { ReactComponent as WorkSvg} from '../assets/work.svg';
import { createCandidatureAnonyme } from '../services/candidatureService';
import { getAnnonceShow } from '../services/annoncesService';

function SubmitApplicationModal({isSendingLoading, companyId}){

  const { t } = useTranslation();

  if( isSendingLoading){
    return(
      <div className='min-h-[40vh] w-full flex items-center justify-center relative z-50 bg-white' >
        <div className='loading' >
          <div className='box' />
        </div>
      </div>
    )
  }

  return(
    <div className='min-h-[40vh] px-20 py-8 w-full flex flex-col items-center justify-between gap-10 relative z-50 bg-white' >
      <div className='flex flex-col items-center' >
        <FiCheckCircle size={84} className='text-green-700 mb-5' />
        <h1 className='text-center text-3xl font-semibold mb-2' >{t("COMMON.THANKS_MSG.PART_1")}</h1>
        <h3 className='text-lg font-semibold text-gray-600' >{t("COMMON.THANKS_MSG.PART_2")}</h3>
      </div>
      <Link to={`/${companyId}`} className='text-sm font-semibold border rounded-lg px-8 py-2.5 hover:shadow-lg' >
        {t("COMMON.THANKS_MSG.BUTTON")}
      </Link>
    </div>
  )
}

export default function Form() {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const {isErrorModalOpen, openErrorModal, closeErrorModal, errorCode, setErrorCode} = useContext(ErrorContext);
  const countryCodesInputRef = useRef(null);
  const countryCodesMenuRef = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  let { companyId, id } = useParams();
  const navigate = useNavigate();
  const cvFileRef = useRef(null);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const uploadedFile = watch('cvFile');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [jobData, setJobData] = useState({});

  const [filteredCodes, setFilteredCodes] = useState([]);
  const [isCountryCodeSelectOpen, setIsCountryCodeSelectOpen] = useState(false);
  const [isEmailValide, setIsEmailValide] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const openSubmitModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsSubmitModalOpen(true);
  }
  const closeSubmitModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsSubmitModalOpen(false);
  }

  const onSubmit = async (data) => {
    // if(!isValidPhoneNumber(data.telephone)){
    //   return setErrorCode("PHONE_INVALID");
    // }

    if (!executeRecaptcha) {
      console.log('reCAPTCHA not yet available');
      return;
    }

    setIsLoading(true);

    const token = await executeRecaptcha('candidature_form');

    const formData = new FormData();

    formData.append('candidature', new Blob([JSON.stringify({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      telephone: data.telephone,
      annonceId: id,
    })], { type: 'application/json' }));

    if (data.cvFile[0]) {
      formData.append('pj', data.cvFile[0]);
    }

    formData.append('recaptchaResponse', token);

    await createCandidatureAnonyme(companyId, formData)
    .then(()=> openSubmitModal() )
    .catch((error)=>{
      error.response.data ? setErrorCode(error.response.data.message) : setErrorCode("ERROR");
      console.error('There was an error:', error);
      closeSubmitModal();
    })
    .finally(()=> setIsLoading(false) )
  };

  const handleCountryCodeInputBlur = (e) => {
    setTimeout(() => {
      if (countryCodesMenuRef.current && !countryCodesMenuRef.current.contains(document.activeElement)) {
        setIsCountryCodeSelectOpen(false);
      }
    }, 0);
  };  

  const handleCountryCodeChange = (value) =>{
    setIsCountryCodeSelectOpen(value.length > 0);

    const cleanedInputValue = value.startsWith('+') ? value.slice(1) : value;
    const filterBy = cleanedInputValue.match(/^\d/) ? 'code' : 'country';

    const filtered = countryCodes.filter(option => {
      const searchValue = cleanedInputValue.toLowerCase();
      return filterBy === 'code' ?
        option.code.startsWith(searchValue) :
        option.country.toLowerCase().includes(searchValue);
    });
    setFilteredCodes(filtered);
  }

  const selectCountryCode = (code)=>{
    setValue('countryCode', `+${code}` );
    setIsCountryCodeSelectOpen(false);
  }

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      // setValue('cvFile', files);
      setValue('cvFile', files, { shouldValidate: true });
    }
  };

  const openFileDialog = () => {
    cvFileRef.current.click();
  };

  const getJobInfo = async () => {
    getAnnonceShow(companyId, id)
    .then((data)=> setJobData(data) )
    .catch((error)=> console.error('There was an error:', error) )
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  };

  useEffect(()=>{
    scrollToTop();
    getJobInfo();
  },[location]);

  useEffect(()=>{
    setFilteredCodes(countryCodes);
  },[]);

  useEffect(()=>{
    if(errorCode.length > 0){
      openErrorModal();
    }
  },[errorCode]);

  const handleClickOutside = (event) => {
    if (countryCodesMenuRef.current && !countryCodesMenuRef.current.contains(event.target)) {
      setIsCountryCodeSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='mainSec mt-6 flex gap-5 justify-between pb-10' 
    >
      <div className='w-full lg:w-[40%] px-4 lg:p-0' >
        <div onClick={()=> navigate(-1) } className='flex max-w-[80%] items-center gap-3 mb-8 cursor-pointer border pl-2 pr-4 py-1.5 rounded-full w-max hover:border-brome hover:text-brome' >
          <FiArrowLeft size={24} className='w-[20%] lg:w-[10%]' />
          <span className='text-base font-semibold truncate overflow-ellipsis' >{jobData.titre}</span>
        </div>
        <Link to={`/${companyId}/login`} >
          <button className='text-sky-500 font-semibold border-2 border-sky-500 hover:text-white hover:bg-sky-500 rounded-lg w-full py-1.5' >
            {t("FORM.SIGNIN_BTN")}
          </button>
        </Link>
        <div className='my-4 flex items-center gap-3 justify-center font-semibold text-gray-500' >
          <div className='w-1/3 bg-gray-400 h-[1px]' /> {t("COMMON.OR")} <div className='w-1/3 bg-gray-400 h-[1px]' />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
          <div className='grid grid-cols-1 lg:grid-cols-2 mb-4 gap-4' >
            <div>
              <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                <label className='text-xs text-gray-500 font-semibold min-w-[50%]' >{t("FORM.LAST_NAME_INPUT.LABEL")} *</label>
                { errors.lastName && <label className='text-xs text-red-700 font-semibold truncate' >{t("COMMON.REQUIRED_ERROR")}</label>}
              </div>
              <input 
                className='border rounded-xl px-3 py-2.5 text-sm w-full hover:border-brome' 
                placeholder={t("FORM.LAST_NAME_INPUT.PLACEHOLDER")}
                {...register("lastName", { required: true })} 
              />
            </div>
            <div>
              <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                <label className='text-xs text-gray-500 font-semibold min-w-[50%]' >{t("FORM.FIRST_NAME_INPUT.LABEL")} *</label>
                { errors.firstName && <label className='text-xs text-red-700 font-semibold truncate' >{t("COMMON.REQUIRED_ERROR")}</label>}
              </div>
              <input 
                className='border rounded-xl px-3 py-2.5 text-sm w-full hover:border-brome' 
                placeholder={t("FORM.FIRST_NAME_INPUT.PLACEHOLDER")}
                {...register("firstName", { required: true })} 
              />
            </div>
          </div>
          <div className='relative' >
            <div className='px-3.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("FORM.EMAIL_INPUT.LABEL")} *</label>
              { errors.email && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input 
              className='border rounded-xl px-3 py-2.5 text-sm w-full mb-4 hover:border-brome pr-8' 
              placeholder={t("FORM.EMAIL_INPUT.PLACEHOLDER")}
              {...register("email", { required: true, pattern: /^\S+@\S+$/i })} 
              onChange={(e)=> validateEmail(e.target.value) }
            />
            {
              isEmailValide 
              ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[2rem]' />
              : <FiXOctagon size={16} className='text-red-700 absolute right-4 top-[2rem]' />
            }
          </div>

          <div className='px-3.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("FORM.PHONE_INPUT.LABEL")} *</label>
            { (errors.telephone) && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <div className='flex gap-2 relative z-50'>
            {/* <input 
              ref={countryCodesInputRef}
              className='border rounded-xl w-[7rem] px-3 py-2.5 text-sm mb-4 hover:border-brome' 
              placeholder="Code pays"
              {...register("countryCode", { required: true })}
              onChange={(e)=> handleCountryCodeChange(e.target.value) }
              onFocus={()=> setIsCountryCodeSelectOpen(true) }
              // onBlur={handleCountryCodeInputBlur}
            />
            {
              isCountryCodeSelectOpen &&
              <div ref={countryCodesMenuRef} className='absolute z-50 border rounded-xl w-[7rem] max-h-52 top-11 left-0 overflow-y-scroll bg-white' >
                <ul className='flex flex-col' >
                  {filteredCodes.map((country)=>(
                    <li key={country.iso} onClick={()=> selectCountryCode(country.code) } className='text-xs font-semibold border-b p-2 hover:bg-gray-100 cursor-pointer' >
                      + {country.code} ({country.iso})
                    </li>
                  ))}
                </ul>
              </div>
            } */}
            <input 
              className='border rounded-xl w-full px-3 py-2.5 text-sm mb-4 hover:border-brome' 
              placeholder={t("FORM.PHONE_INPUT.PLACEHOLDER")}
              {...register("telephone", { required: true })} 
            />
          </div>
          {/* <input 
            className='border rounded-xl px-3 py-2.5 text-sm w-full mb-4 hover:border-brome' 
            placeholder={t("FORM.PHONE_INPUT.PLACEHOLDER")}
            {...register("telephone", { required: true })} 
          /> */}

          <div className='px-3.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("FORM.CV_INPUT.LABEL")} *</label>
            { errors.cvFile && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <div 
            onClick={openFileDialog} 
            onDragOver={onDragOver}
            onDrop={onDrop}
            className='h-28 px-1 border border-dashed rounded-xl flex flex-col mb-4 items-center justify-center cursor-pointer hover:border-brome' 
          >
            {(uploadedFile && uploadedFile.length > 0) 
              ? <p>{uploadedFile[0].name}</p>
              : 
              <>
                <FiUploadCloud size={28} className='text-gray-500' />
                <p className='text-sm text-gray-500 text-center' >{t("FORM.CV_INPUT.PLACEHOLDER")}</p>
              </>
            }
          </div>

          <input
            {...register('cvFile', { required: true })}
            type="file"
            style={{ display: 'none' }}
            id="fileUpload"
            accept=".pdf,.doc,.docx"
            onChange={(e) => {
              const files = e.target.files;
              setValue('cvFile', files.length > 0 ? files : null, { shouldValidate: true });
            }}
            ref={cvFileRef}
          />

          {
            isLoading ?
            <div className='relative border border-brome px-5 h-[42px] rounded-lg mt-6'  >
              <div className='loader' style={{top: '8px'}} /> 
            </div>
            :
            <input 
              type="submit" 
              value={t("FORM.BUTTON")}
              className='text-sm font-semibold bg-brome px-5 py-2.5 rounded-lg text-white mt-6 cursor-pointer hover:shadow-lg hover:bg-brome-hover' 
            />
          }

        </form>
      </div>
      <div className='w-[55%] hidden lg:flex flex-col items-center gap-5 ' >
        <h1 className='font-light text-5xl text-center' >{t("FORM.TITLE")}</h1>
        <WorkSvg width="45vw" />
      </div>
      <Modal isOpen={isSubmitModalOpen} close={closeSubmitModal} >
        <SubmitApplicationModal isSendingLoading={isLoading} companyId={companyId} />
      </Modal>
      <ErrorModal isOpen={isErrorModalOpen} close={closeErrorModal} title={t(`ERRORS.${errorCode}`)} />
    </motion.div>
  )
}
