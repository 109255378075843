import React, { useEffect, useState, useRef, useContext } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { FiCheckCircle, FiUploadCloud, FiX } from 'react-icons/fi'; 
import { Link, useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { UserContext } from '../Contexts/UserContext';
import axios from 'axios';
import { isValidPhoneNumber } from 'libphonenumber-js';

import auth_API from '../services/api';

import countryCodes from '../utils/countryCodes.json';
import { accountUpdate } from '../services/accountService';

export default function CompleteSignUpModal({children, isOpen, close}) {
  
  const countryCodesInputRef = useRef(null);
  const countryCodesMenuRef = useRef(null);
  const navigate = useNavigate();
  const cvFileRef = useRef(null);
  let { companyId } = useParams();
  const { tenant, setTenant, user } = useContext(UserContext);
  const { t } = useTranslation();
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const uploadedFile = watch('cvFile');
  const [errorCode, setErrorCode] = useState("");

  const [loading, setLoading] = useState(false);
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [isCountryCodeSelectOpen, setIsCountryCodeSelectOpen] = useState(false);

  const [logoImg, setLogoImg] = useState("");

  const onSubmit = async (data) =>{
    // if(!isValidPhoneNumber(data.countryCode+data.telephone)){
    //   console.log(data.countryCode+data.telephone);
    //   return setErrorCode("PHONE_INVALID");
    // }
    setErrorCode("");
  
    setLoading(true);

    const formData = new FormData();
    formData.append('userDto', new Blob([JSON.stringify({
      email: user.email, 
      firstName: user.firstName,
      lastName: user.lastName,
      telephone: data.telephone,
    })], { type: 'application/json' }));

    if (data.cvFile[0]) {
      formData.append('pj', data.cvFile[0]);
    }

    accountUpdate(companyId, formData)
    .then(()=>{
      setTimeout(() => {
        close();
        setLoading(false);
      }, 1000);
    })
    .catch((err)=>{
      setErrorCode(err.response.data.message);
      console.error('There was an error:', err);
      setLoading(false);
    })

    // const config = {
    //   headers: {
    //     'XTENANT-HEADER': companyId,
    //   },
    // };

    // try {
    //   const response = await auth_API.put('/account/updatecurrent', formData, config);
    //   console.log(response.data);
    //   setTimeout(() => {
    //     close();
    //     setLoading(false);
    //   }, 1000);
    // } catch (error) {
    //   setErrorCode(error.response.data.message);
    //   console.error('There was an error:', error);
    //   setLoading(false);
    // }
  };

  const handleCountryCodeInputBlur = (e) => {
    setTimeout(() => {
      if (countryCodesMenuRef.current && !countryCodesMenuRef.current.contains(document.activeElement)) {
        setIsCountryCodeSelectOpen(false);
      }
    }, 0);
  };  

  const handleCountryCodeChange = (value) =>{
    setIsCountryCodeSelectOpen(value.length > 0);

    const cleanedInputValue = value.startsWith('+') ? value.slice(1) : value;
    const filterBy = cleanedInputValue.match(/^\d/) ? 'code' : 'country';

    const filtered = countryCodes.filter(option => {
      const searchValue = cleanedInputValue.toLowerCase();
      return filterBy === 'code' ?
        option.code.startsWith(searchValue) :
        option.country.toLowerCase().includes(searchValue);
    });
    setFilteredCodes(filtered);
  }

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      // setValue('cvFile', files);
      setValue('cvFile', files, { shouldValidate: true });
    }
  };

  const openFileDialog = () => {
    cvFileRef.current.click();
  };

  useEffect(()=>{
    setFilteredCodes(countryCodes);
  },[]);

  if(isOpen){
    return (
      <div className='fixed w-full h-screen top-0 left-0 bg-transparent z-50' >
        <div className='absolute lg:block hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white z-10 p-4 w-max max-w-[40%] max-h-[calc(100%_-_4rem)] rounded-xl overflow-y-auto overflow-x-hidden flex-wrap' >

          <div className='flex justify-between mb-5'  >
            <p className='font-semibold' >{t("AUTH.COMPLETE_SIGNUP.TITLE")}</p>
            <FiX size={22} onClick={()=> close() } className='cursor-pointer' />
          </div>

          <div>
            {
              loading ?
              <div className='h-[30vh] flex items-center justify-center' >
                <div className='loading' >
                  <div className='box' />
                </div>
              </div>
              :
              <>

                { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-3' >{t(`ERRORS.${errorCode}`)}</p>}
                
                <form onSubmit={handleSubmit( (data)=> onSubmit(data) )} className='flex flex-col w-full' >

                  <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                    <label className='text-xs text-gray-500 font-semibold' >{t("FORM.PHONE_INPUT.LABEL")} *</label>
                    { (errors.telephone) && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <div className='flex gap-2 relative'>
                    {/* <input 
                      ref={countryCodesInputRef}
                      className='border rounded-xl w-[7rem] px-3 py-2.5 text-sm mb-4 hover:border-brome' 
                      placeholder="Code pays"
                      {...register("countryCode", { required: true })}
                      onChange={(e)=> handleCountryCodeChange(e.target.value) }
                      onFocus={()=> setIsCountryCodeSelectOpen(true) }
                      onBlur={handleCountryCodeInputBlur}
                    />
                    {
                      isCountryCodeSelectOpen &&
                      <div ref={countryCodesMenuRef} className='absolute border rounded-xl w-[7rem] max-h-52 top-11 left-0 overflow-y-scroll bg-white' >
                        <ul className='flex flex-col' >
                          {filteredCodes.map((country)=>(
                            <li key={country.iso} onClick={()=>{setValue('countryCode', `+${country.code}` )} } className='text-xs font-semibold border-b p-2 hover:bg-gray-100 cursor-pointer' >
                              + {country.code} ({country.iso})
                            </li>
                          ))}
                        </ul>
                      </div>
                    } */}
                    <input 
                      className='border rounded-xl w-full px-3 py-2.5 text-sm mb-4 hover:border-brome' 
                      placeholder={t("FORM.PHONE_INPUT.PLACEHOLDER")}
                      {...register("telephone", { required: true })} 
                    />
                  </div>

                  <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                    <label className='text-xs text-gray-500 font-semibold' >{t("FORM.CV_INPUT.LABEL")} *</label>
                    { errors.cvFile && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <div 
                    onClick={openFileDialog} 
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    className='h-28 px-1 border border-dashed rounded-xl flex flex-col mb-4 items-center justify-center cursor-pointer hover:border-brome' 
                  >
                    {(uploadedFile && uploadedFile.length > 0) 
                      ? <p>{uploadedFile[0].name}</p>
                      : 
                      <>
                        <FiUploadCloud size={28} className='text-gray-500' />
                        <p className='text-sm text-gray-500 text-center' >{t("FORM.CV_INPUT.PLACEHOLDER")}</p>
                      </>
                    }
                  </div>

                  <input
                    {...register('cvFile', { required: true })}
                    type="file"
                    style={{ display: 'none' }}
                    id="fileUpload"
                    accept=".pdf,.doc,.docx"
                    onChange={(e) => {
                      const files = e.target.files;
                      setValue('cvFile', files.length > 0 ? files : null, { shouldValidate: true });
                    }}
                    ref={cvFileRef}
                  />

                  <input 
                    type="submit" 
                    value={t("COMMON.SAVE")}
                    className='text-sm font-semibold bg-brome px-5 py-2.5 rounded-lg text-white mt-6 cursor-pointer hover:shadow-lg hover:bg-brome-hover' 
                  />
                </form>
              </>
            }
          </div>

        </div>
        <div className='absolute z-0 top-0 bg-black/50 w-full h-screen' onClick={()=> close() } />
      </div>
    )
  }else{
    return (
      null
    )
  }
}
