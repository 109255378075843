import auth_API from "./api"

export const getUser = async (companyId)=>{
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
  };
  try {
    const response = await auth_API.get('/account', config);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching user data:', error);
    throw error;
  }
}

export const getUserCv = async (companyId)=>{
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
    responseType: 'blob',
  };
  try {
    const response = await auth_API.get('/account/download', config);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching user data:', error);
    throw error;
  }
}