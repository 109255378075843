import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, Link, useLocation, useParams, useNavigate } from "react-router-dom";
import cx from 'classnames';
import { FiMenu, FiSearch } from 'react-icons/fi';
import { motion } from "framer-motion";
import axios from 'axios';

import bromeLogoDark from '../assets/brome-logo-dark.png';
import consultLogo from '../assets/consult-white-logo.png';
import lookaHrLogo from '../assets/Logo_LookaHR.png';
import { UserContext } from '../Contexts/UserContext';
import { ReactComponent as WorkingSvg} from '../assets/working.svg';
import { ReactComponent as Working2Svg} from '../assets/working2.svg';
import { ReactComponent as WorkingV2} from '../assets/WorkingV2.svg';
import workingImg from '../assets/working.jpg'
import { getAllTenants } from '../services/tenantService';

const words = ['rêves', 'ambitions', 'passions', 'aspirations', 'objectifs', 'projets', 'idéaux', 'désirs'];

const dataTest = [
  {id: 1, name: "Brome consulting & technology (Maroc)"},
  {id: 2, name: "Brome consulting & technology (Maroc)"},
  {id: 3, name: "Brome consulting & technology (Maroc)"},
]

function CompanyComponent({item}){

  const [isHovered, setIsHovered] = useState(false);
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);
  const [imgUrl, setImgUrl]  = useState("");

  useEffect(()=>{
    if(isHovered){
      setTimeout(() => {
        setIsAnimationFinished(true);
      }, 300);
    }else{
      setIsAnimationFinished(false);
    }
  },[isHovered]);

  const downloadFile = async () => {
    if(item){
      const apiServerUrl = process.env.REACT_APP_DOWNLOAD_URL + '/download';
      const directory = "master";
  
      try {
        const url = `${apiServerUrl}/${directory}/${item.logo.storedFilename}`;
        const response = await axios.get(url, {
          responseType: 'blob',
        });
  
        const localUrl = URL.createObjectURL(response.data);
        setImgUrl(localUrl);
      } catch (error) {
        console.error('Download error:', error);
      }
    }
  };

  useEffect(()=>{
    downloadFile();
  },[item]);

  return(
    <Link to={"/"+item.uuid} >
      <motion.div 
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        whileHover={{ scale: 1.03 }} 
        className={cx('border rounded-lg h-full p-3 py-5 flex flex-col gap-3 items-center cursor-pointer hover:border-[#205489] relative',{'customShadow': !isHovered})} >
        <motion.div 
          className='text-white bg-[#205489] rounded-full h-5 text-xs font-semibold absolute top-3 right-3 shadow flex items-center justify-center'
          initial={{ width: 0 }}
          animate={{ width: isHovered ? "130px" : 0}}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
        {( isHovered && isAnimationFinished) ? "Voir les annonces" : null }
        </motion.div>
        <div className='w-40 max-h-[60px] h-full' >
          <img src={imgUrl} className='w-full h-full object-contain' />
        </div>
        <h3 className='font-semibold' >{item.intitule}</h3>
      </motion.div>
    </Link>
  )
}

export default function Companies() {

  const { t } = useTranslation();
  const { isLogged } = useContext(UserContext);

  const [currWordIndex, setCurrWordIndex] = useState(0);
  const [allCompanies, setAllCompanies] = useState([]);

  const fetchAllTenants = async () => {
    getAllTenants()
    .then((data)=>{ 
      setAllCompanies(data);
      console.log(data);
    })
    .catch((error)=> console.error('There was an error fetching the tenants:', error) )
  };

  useEffect(()=>{
    fetchAllTenants();
  },[])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrWordIndex((currentIndex) => (currentIndex + 1) % words.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='lg:px-10 px-4 pt-4' >
      <div className='absolute hidden md:flex top-0 right-10 h-[80vh] items-center z-[-1] w-[40vw]' >
        <Working2Svg width="40vw" />
        {/* <img src={workingImg} className='w-full h-full object-contain'  /> */}
      </div>
      <div className='flex items-center justify-between py-5' >
        <div className='w-40 md:w-52 h-full mr-10' >
          <img src={lookaHrLogo} className='w-full h-full object-contain' />
        </div>
        {/* <ul className='flex items-center gap-6 font-semibold' >
          { !isLogged &&
            <li className='hidden lg:block' >
              <Link to="/login" className='group flex items-center flex-col cursor-pointer' >
                {t("COMMON.SIGNIN")}
                <div className='border-b-2 border-[#24695c] w-0 group-hover:w-2/3 transition-all duration-300' />
              </Link>
            </li>
          }
          {
            !isLogged &&
            <li className='hidden lg:block' >
              <Link to="/sign-up" className='group flex items-center flex-col cursor-pointer' >
                {t("COMMON.SIGNUP")}
                <div className='border-b-2 border-[#24695c] w-0 group-hover:w-2/3 transition-all duration-300' />
              </Link>
            </li>
          }
          <li className='lg:hidden block' >
            <FiMenu size={22} />
          </li>
        </ul> */}
      </div>
      
      <div className='min-h-[40vh] md:min-h-[50vh] flex items-center relative z-10' >
        <h1 className='text-5xl md:text-6xl font-light leading-[4rem] md:leading-[5rem]' >
          Trouvez l'emploi de vos <motion.span
            className='font-bold text-[#205489]'
            key={words[currWordIndex]}
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30 }}
            transition={{ duration: 0.5 }}
            style={{ display: 'inline-block' }}
          >
            {words[currWordIndex]}
          </motion.span> <br/> aujourd'hui
        </h1>
      </div>
      <div className='border rounded-t-xl p-6 min-h-[40vh] relative bg-white' >
        <div className='absolute bg-white px-5 top-[-14px] left-5 text-gray-500 font-semibold' >
          Liste des entreprises
        </div>
        <div className='border rounded-lg px-4 py-3 text-sm w-full mb-8 relative' >
          <input
            placeholder='rechercher une entreprise'
            className='w-full'
          />
          <FiSearch size={18} color='#888' className='absolute right-4 top-3.5' />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-5' >
          {allCompanies.map((it)=> <CompanyComponent key={it.uuid} item={it} /> )}
        </div>
      </div>
    </div>
  )
}
