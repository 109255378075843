import axios from "axios";

export const getAllTenants = async ()=>{
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tenants/all`);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the tenants:', error);
    throw error;
  }
}

export const getTenantData = async (companyId)=>{
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tenants/show/${companyId}`);
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}

export const getLogoFile = async (fileName) => {
  if(fileName){
    const apiServerUrl = process.env.REACT_APP_DOWNLOAD_URL + '/download';
    const directory = "master";

    try {
      const url = `${apiServerUrl}/${directory}/${fileName}`;
      const response = await axios.get(url, {
        responseType: 'blob',
      });
      const localUrl = URL.createObjectURL(response.data);
      return localUrl ;
    } catch (error) {
      console.error('Download error:', error);
      // throw error;
    }
  }
};