import React,{ createContext, useState, useEffect } from "react";
import axios from "axios";

export const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const openErrorModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsErrorModalOpen(true);
  }
  const closeErrorModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsErrorModalOpen(false);
    setErrorCode("");
  }

  const values = {
    isErrorModalOpen,
    errorCode,
    setErrorCode,
    openErrorModal,
    closeErrorModal
  }

  return (
    <ErrorContext.Provider value={values}>
      {children}
    </ErrorContext.Provider>
  );
};