import React, {useState} from 'react';
import { motion } from 'framer-motion';
import cx from 'classnames';
import { FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { FiltersContainer } from './Home';

export default function MobileFilterModal({isOpen, close, filtersData, filters, setFilters, isLoading}) {

  const { t } = useTranslation();

  return (
    <div className={cx('fixed top-0 left-0 w-full z-20 flex-col items-center justify-end',{'h-[100vh] flex':isOpen, 'h-0 hidden': !isOpen})} >
      <div className='h-full w-full bg-[rgba(0,0,0,0.3)] absolute top-O left-0' onClick={()=> close() } />
      <motion.div
        initial={false}
        animate={{
          height: isOpen ? '75vh' : '0px',
        }}
        transition={{ duration: 0.5 }} 
        className='w-[95vw] bg-white relative z-30 rounded-t-lg customShadow px-4 py-3 overflow-y-auto'
      >
        <FiX onClick={()=> close() } size={20} className='absolute top-3 right-4' />
        <FiltersContainer filtersData={filtersData} filters={filters} setFilters={setFilters} isLoading={isLoading} />
        <div className='w-full h-12' />
        <div className='fixed bottom-0 left-[4vw] w-[92vw] py-2 px-[5%]' >
          <button onClick={()=> close() } className='text-sm font-semibold bg-brome px-5 py-3 w-full rounded-xl text-white hover:shadow-lg hover:bg-brome-hover' >
            {t("COMMON.SEARCH")}
          </button>
        </div>
      </motion.div>
    </div>
  )
}
