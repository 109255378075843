import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, Link, useLocation, useParams, useNavigate } from "react-router-dom";
import cx from 'classnames';
import { motion } from "framer-motion";
import { FiMail, FiMap, FiMenu, FiPhone } from 'react-icons/fi';
import axios from 'axios';

import { UserContext } from '../Contexts/UserContext';
import bromeLogo from '../assets/brome-logo.png';
import bromeLogoDark from '../assets/brome-logo-dark.png';
import lookaHrLogo from '../assets/Logo_LookaHR.png';
import MobileSideBar from './MobileSideBar';
import { getLogoFile, getTenantData } from '../services/tenantService';

export default function Root() {

  const { t } = useTranslation();
  const location = useLocation();
  let { companyId } = useParams();
  const { isLogged, setTenant } = useContext(UserContext);

  const [navBarTheme, setNavBarTheme] = useState("light");
  const [isMobileSideBarOpen, setIsMobileSideBarOpen] = useState(false);

  const [darkLogo, setDarkLogo] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");

  const openMobileSideBar = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsMobileSideBarOpen(true);
  }
  const closeMobileSideBar = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsMobileSideBarOpen(false);
  }

  useEffect(()=>{
    if(location.pathname.includes("form") || location.pathname.includes("applications") || location.pathname.includes("compte")){
      setNavBarTheme("dark");
    }else{
      setNavBarTheme("light")
    }
  },[location]);

  useEffect(()=>{
    if(companyId){
      getTenantData(companyId).then((data)=>{
        setTenant(data);
        getLogoFile(data.logo.storedFilename).then((img)=> setDarkLogo(img) );
        getLogoFile(data.whiteLogo.storedFilename).then((img)=> setWhiteLogo(img) );
      })
    }
  },[companyId]);

  return (
    <div >
      <div className={cx('flex items-center justify-between lg:px-10 px-4 py-4', {"text-black": navBarTheme === "dark", "text-white": navBarTheme === "light"})} >
        <ul className='flex items-center gap-6 font-semibold' >
          <li>
            <Link to={"/"+companyId} >
              <div className='w-40 h-14 mr-10' >
                <img src={navBarTheme === "dark" ? darkLogo : whiteLogo} className='w-auto h-full object-contain ' />
              </div>
            </Link>
          </li>
          <li className='hidden lg:block' >
            <Link to={"/"+companyId} className='group flex items-center flex-col cursor-pointer' >
              {t("NAV.HOME")}
              <div className='border-b-2 border-brome w-0 group-hover:w-2/3 transition-all duration-300' />
            </Link>    
          </li>
          <li className='hidden lg:block'  >
            <Link to={"/"+companyId+"/#jobs"} className='group flex items-center flex-col cursor-pointer' >
              {t("NAV.JOBS")}
              <div className='border-b-2 border-brome w-0 group-hover:w-2/3 transition-all duration-300' />
            </Link>
          </li>
          {
            isLogged &&
            <li className='hidden lg:block'  >
              <Link to={`/${companyId}/applications`} className='group flex items-center flex-col cursor-pointer' >
                {t("NAV.APPLICATIONS")}
                <div className='border-b-2 border-brome w-0 group-hover:w-2/3 transition-all duration-300' />
              </Link>
            </li>
          }
        </ul>
        <ul className='flex items-center gap-6 font-semibold' >
          { isLogged &&
            <li className='hidden lg:block' >
              <Link to={`/${companyId}/compte`} className='group flex items-center flex-col cursor-pointer' >
                {t("COMMON.ACCOUNT")}
                <div className='border-b-2 border-brome w-0 group-hover:w-2/3 transition-all duration-300' />
              </Link>
            </li>
          }
          { !isLogged &&
            <li className='hidden lg:block' >
              <Link to={`/${companyId}/login`} className='group flex items-center flex-col cursor-pointer' >
                {t("COMMON.SIGNIN")}
                <div className='border-b-2 border-brome w-0 group-hover:w-2/3 transition-all duration-300' />
              </Link>
            </li>
          }
          {
            !isLogged &&
            <li className='hidden lg:block' >
              <Link to={`/${companyId}/sign-up`} className='group flex items-center flex-col cursor-pointer' >
                {t("COMMON.SIGNUP")}
                <div className='border-b-2 border-brome w-0 group-hover:w-2/3 transition-all duration-300' />
              </Link>
            </li>
          }
          <li className='lg:hidden block' onClick={()=> openMobileSideBar() } >
            <FiMenu size={22} />
          </li>
        </ul>
      </div>
      <MobileSideBar close={closeMobileSideBar} isOpen={isMobileSideBarOpen} />
      <div className='flex flex-col items-center' >
        <Outlet />
      </div>
      <div className='mt-10 w-full px-5 lg:px-10 pt-10 pb-5 relative bg-[#F8F9FA] flex flex-col lg:flex-row justify-between' >
        {/* <Link className='absolute top-[-2rem] left-10 px-5 bg-white' to={"/"+companyId} >
          <div className='w-36 h-14' >
            <img src={darkLogo} className='w-auto h-full object-contain ' />
          </div>
        </Link> */}
        <div className='flex flex-col lg:flex-row' >
          <Link className='' to={"/"+companyId} >
            <div className='w-36 h-14 mb-8 lg:mb-0' >
              <img src={darkLogo} className='w-auto h-full object-contain mix-blend-multiply' />
            </div>
          </Link>
          <div className='lg:ml-40 mb-8 lg:mb-0' >
            <ul className='flex flex-col gap-3 text-sm' >
              <li className='font-bold text-gray-400 mb-2' >BROME Consulting & Technologie</li>
              <li className='flex gap-2 items-center text-gray-600' >
                <FiMail className='min-w-[20px]' />
                <span className='font-semibold' >recrute@brome.ma</span>
              </li>
              <li className='flex gap-2 items-center text-gray-600' >
                <FiPhone className='min-w-[20px]' />
                <span className='font-semibold' >+212-5225-25652</span>
              </li>
              <li className='flex gap-2 items-center text-gray-600' >
                <FiMap className='min-w-[20px]' />
                <span className='font-semibold' >1er Etage, N° 15, Naciri, Alpha Business Center, Bd Zoulikha Nasri, Casablanca 20000</span>
              </li>
            </ul>
          </div>
          <div className='lg:ml-40 mb-8 lg:mb-0' >
            <ul className='flex flex-col gap-3 text-sm' >
              <li className='font-bold text-gray-400 mb-2' >Liens utiles</li>
              <li className='flex gap-2 items-center text-gray-600' >
                <Link to={"/"+companyId} >
                  <span className='font-semibold hover:underline' >{t("NAV.HOME")}</span>
                </Link>
              </li>
              <li className='flex gap-2 items-center text-gray-600' >
                <Link to={"/"+companyId+"/#jobs"} >
                  <span className='font-semibold hover:underline' >{t("NAV.JOBS")}</span>
                </Link>
              </li>
              { isLogged &&
                <li className='flex gap-2 items-center text-gray-600' >
                  <Link to={`/${companyId}/applications`} >
                    <span className='font-semibold hover:underline' >{t("NAV.APPLICATIONS")}</span>
                  </Link>
                </li>
              }
              { isLogged &&
                <li className='flex gap-2 items-center text-gray-600' >
                  <Link to={`/${companyId}/compte`} >
                    <span className='font-semibold hover:underline' >{t("COMMON.ACCOUNT")}</span>
                  </Link>
                </li>
              }
            </ul>
          </div>
        </div>
        <div className='pb-5 flex flex-col justify-between' >
          <div className='w-32 h-max mb-2 lg:mb-0' >
            <img src={lookaHrLogo} className='w-full h-full object-contain' />
          </div>
          <p className='text-xs text-gray-400' >© 2024 BROME. ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </div>
  )
}
