import React from 'react';
import { FiX, FiXOctagon } from 'react-icons/fi';

export default function ErrorModal({children, isOpen, close, title}) {

  if(isOpen){
    return (
      <div className='fixed w-full h-screen top-0 left-0 bg-transparent z-50' >
        <div className='absolute lg:block hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white z-10 p-4 w-max min-w-[40%] max-w-screen-md max-h-[calc(100%_-_4rem)] rounded-xl overflow-y-auto overflow-x-hidden flex-wrap' >
          <div className='flex items-center justify-between text-gray-600'  >
            <p className='text-sm font-semibold ' >Erreur</p>
            <FiX size={22} onClick={()=> close() } className='cursor-pointer' />
          </div>
          <div className='mt-2 flex items-center justify-center flex-col gap-6' >
            <FiXOctagon size={104} color='#D6323A' />
            <h2 className='text-lg font-bold text-center' >{title}</h2>
          </div>
        </div>
        <div className='absolute lg:hidden top-[5%] left-0 bg-white z-10 p-3 w-full max-h-[90%] h-max rounded-xl overflow-y-auto overflow-x-hidden flex-wrap' >
          <div className='flex justify-between' onClick={()=> close() } >
            <svg fill="none" stroke="currentColor" className="w-7 h-7 text-aman-orange cursor-pointer mb-4" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
          </div>
          {title}
        </div>
        <div className='absolute z-0 top-0 bg-black/50 w-full h-screen' onClick={()=> close() } />
      </div>
    )
  }else{
    return (
      null
    )
  }
}