import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { MdOutlineWhatsapp } from "react-icons/md";
import { FiSearch, FiBriefcase, FiFacebook, FiLinkedin, FiMapPin, FiCalendar, FiFileText, FiShare2, FiDownload, FiCheckCircle, FiGrid, FiMap, FiChevronLeft, FiTrash, FiTrash2 } from 'react-icons/fi'; 
import axios from 'axios';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';
import { saveAs } from 'file-saver';

import auth_API from '../services/api';
import { UserContext } from '../Contexts/UserContext';
import Modal from './FullModal';
import homeBg from '../assets/homeBg.png';
import { getUser, getUserCv } from '../services/userService';
import { getAllAnnonces, getAnnonceShow, getAnnoncesSearch } from '../services/annoncesService';
import { createCandidature, createCandidatureCustomeCv } from '../services/candidatureService';
import CompleteSignUpModal from '../Auth/CompleteSignUpModal';

function SubmitApplicationModal({jobInfo, companyId, getApplicatedJobs, openCompleteSignUpModal}){

  const { t } = useTranslation();
  const cvInputRef = useRef(null);

  const [user, setUser] = useState({});
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isSendingLoading, setIsSendingLoading] = useState(false);
  const [isCorrectlySubmitted, setIsCorrectlySubmitted] = useState(false);
  const [cvFile, setCvFile] = useState(null);

  const downloadCvFile = async ()=>{
    await getUserCv(companyId).then((cvBlob)=>{
      saveAs(cvBlob, user.profil.cv.originalFilename);
    });
  }

  const submit = async ()=>{
    if(user.profil?.cv !== null){
      if(cvFile){
        const formData = new FormData();
        formData.append("pj", cvFile);
        setIsSendingLoading(true);
        createCandidatureCustomeCv(companyId, jobInfo.id, formData)
        .then(()=>{
          setIsCorrectlySubmitted(true);
          getApplicatedJobs();
        })
        .catch((error)=> console.log(error) )
        .finally(()=> setIsSendingLoading(false) )
      }else{
        setIsSendingLoading(true);
        createCandidature(companyId, jobInfo.id, user.profil.cv.originalFilename)
        .then(()=>{
          setIsCorrectlySubmitted(true);
          getApplicatedJobs();
        })
        .catch((error)=> console.log(error) )
        .finally(()=> setIsSendingLoading(false) )
      }
    }else{
      openCompleteSignUpModal();
    }
  }

  const getUserData = async ()=>{
    
    setIsFetchLoading(true);

    getUser(companyId)
    .then((data)=>{ setUser(data) })
    .catch((error)=> console.error('There was an error fetching user data:', error) )
    .finally(()=>
      setTimeout(() => {
        setIsFetchLoading(false);
      }, 500)
    )
  }

  useEffect(()=>{
    getUserData();
  },[]);

  if(isFetchLoading || isSendingLoading){
    return(
      <div className='min-h-[40vh] w-full flex items-center justify-center relative z-50 bg-white' >
        <div className='loading' >
          <div className='box' />
        </div>
      </div>
    )
  }

  if(isCorrectlySubmitted){
    return(
      <div className='min-h-[40vh] px-20 py-8 w-full flex flex-col items-center justify-between gap-10 relative z-50 bg-white' >
        <div className='flex flex-col items-center' >
          <FiCheckCircle size={84} className='text-green-700 mb-5' />
          <h1 className='text-center text-3xl font-semibold mb-2' >{t("COMMON.THANKS_MSG.PART_1")}</h1>
          <h3 className='text-lg font-semibold text-gray-600' >{t("COMMON.THANKS_MSG.PART_2")}</h3>
        </div>
        <Link to={`/${companyId}`} className='text-sm font-semibold border rounded-lg px-8 py-2.5 hover:shadow-lg' >
          {t("COMMON.THANKS_MSG.BUTTON")}
        </Link>
      </div>
    )
  }

  return(
    <div>
      <h1 className='text-center text-2xl font-semibold' >{jobInfo.titre}</h1>
      <h4 className='font-semibold text-sm text-gray-500 text-center mb-10' >{moment(jobInfo.datePublication).locale('fr').calendar()} | {jobInfo.ville.libelle}</h4>
      <div className='mb-2 grid grid-cols-1 lg:grid-cols-2 gap-2' >
        <div>
          <label className='px-3 mb-0.5 text-xs text-gray-400' >{t("FORM.LAST_NAME_INPUT.LABEL")}</label>
          <input 
            className='border rounded-lg px-3 py-2 text-sm w-full font-semibold text-[#5A5A5A]' 
            value={user.lastName}
            disabled
          />
        </div>
        <div>
          <label className='px-3 mb-0.5 text-xs text-gray-400' >{t("FORM.FIRST_NAME_INPUT.LABEL")}</label>
          <input 
            className='border rounded-lg px-3 py-2 text-sm w-full font-semibold text-[#5A5A5A]' 
            value={user.firstName}
            disabled
          />
        </div>
      </div>
      <div className='mb-2 ' >
        <label className='px-3 mb-0.5 text-xs text-gray-400' >{t("FORM.EMAIL_INPUT.LABEL")}</label>
        <input 
          className='border rounded-lg px-3 py-2 text-sm w-full font-semibold text-[#5A5A5A]' 
          value={user.email}
          disabled
        />
      </div>
      <div className='mb-2 ' >
        <label className='px-3 mb-0.5 text-xs text-gray-400' >{t("FORM.PHONE_INPUT.LABEL")}</label>
        <input 
          className='border rounded-lg px-3 py-2 text-sm w-full font-semibold text-[#5A5A5A]' 
          value={user.telephone}
          disabled
        />
      </div>
      <div className='mb-2 ' >
        <label className='px-3 mb-0.5 text-xs text-gray-400' >{t("FORM.CV_INPUT.LABEL")}</label>
        <div className='flex items-center justify-between gap-2' > 
          {
            cvFile ?
            <div className='flex items-center justify-between border rounded-lg px-3 py-2 text-sm w-full font-semibold text-[#5A5A5A]'  >
              <div className='flex gap-2 items-center' >
                <FiFileText size={16} />
                <p>{cvFile.name}</p>
              </div>
              <FiTrash2 onClick={()=> setCvFile(null) } size={16} className='cursor-pointer hover:text-brome' />
            </div>
            :
            <div className='flex items-center justify-between border rounded-lg px-3 py-2 text-sm w-full font-semibold text-[#5A5A5A]'  >
              <div className='flex gap-2 items-center' >
                <FiFileText size={16} />
                <p>{ user.profil?.cv !== null ? user.profil?.cv?.originalFilename : "Ajouter un cv"}</p>
              </div>
              <FiDownload onClick={()=> downloadCvFile() } size={16} className='cursor-pointer hover:text-brome' />
            </div>
          }
          <button onClick={()=> cvInputRef.current.click() } className='border rounded-lg px-3 py-2 w-max hover:border-brome hover:text-brome text-sm font-semibold text-[#5A5A5A] whitespace-nowrap' >
            {t("JOB.CUSTOM_CV_BTN")}
          </button>
          <input
            type="file"
            ref={cvInputRef}
            className='hidden'
            onChange={(e)=> setCvFile(e.target.files[0]) }
          />
        </div>
      </div>
      <div className='mt-10 flex items-center justify-between gap-4' >
        <Link to={`/${companyId}/compte`} className='text-sm font-semibold border text-center rounded-lg px-8 w-1/2 py-2.5 hover:shadow-lg text-gray-500' >
          {t("JOB.EDIT_PROFILE_BTN")}
        </Link>
        <button onClick={()=> submit() } className='text-sm font-semibold bg-brome px-8 py-2.5 w-1/2 h-max text-center rounded-lg text-white hover:shadow-lg hover:bg-brome-hover' >
          {t("JOB.APPLY_NOW_BTN")}
        </button>
      </div>
    </div>
  )
}

export default function Job() {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  let { companyId, id } = useParams();
  const { tenant, isLogged, applicatedJobs, getApplicatedJobs } = useContext(UserContext);

  const mainDivRef = useRef(null);
  const firstContainerRef = useRef(null);
  const bgImgRef = useRef(null);
  const shareMenuRef = useRef(null);

  const [firstContainerHeight, setFirstContainerHeight] = useState(0);
  const [bgImgHeight, setBgImgHeight] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isImgLoaded, setIsImageLoaded] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [jobInfo, setJobInfo] = useState({});
  const [bannerImg, setBannerImg] = useState("");
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isCompleteSignUpModalOpen, setIsCompleteSignUpModalOpen] = useState(false);
  const [lastJobs, setLastJobs] = useState([]);
  const [isSuggestionLoading, setIsSuggestionLoading] = useState(false);

  const shareUrl = `https://job.lookahr.com/#/${companyId}/${id}` ;
  const whatsappMessage = `Regarde cette annonce: https://job.lookahr.com/#/${companyId}/${id}`;

  const openSubmitModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsSubmitModalOpen(true);
  }
  const closeSubmitModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsSubmitModalOpen(false);
  }

  const openCompleteSignUpModal = ()=>{
    setIsCompleteSignUpModalOpen(true);
  }
  const closeCompleteSignUpModal = ()=>{
    setIsCompleteSignUpModalOpen(false);
  }

  useEffect(() => {
    function handleResize() {
      if(Object.keys(jobInfo).length === 0 || isBannerLoading) return ;
      setTimeout(() => {
        if(firstContainerRef.current && bgImgRef.current){
          const bounding = firstContainerRef.current.getBoundingClientRect();
          const imgHeight = bgImgRef.current.getBoundingClientRect().height;
          setBgImgHeight(imgHeight);
          setFirstContainerHeight(bounding.height);
        }
      }, 100);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isPageLoading, isBannerLoading, jobInfo]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  };

  useEffect(()=>{
    setTimeout(() => {
      setIsImageLoaded(true);
    }, 110);
  },[])

  useEffect(()=>{
    scrollToTop();
    setTimeout(() => {
      setIsPageLoading(false);
    }, 1000);
  },[location]);

  const handleClickOutside = (event) => {
    if (shareMenuRef.current && !shareMenuRef.current.contains(event.target)) {
      setIsShareMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getJobInfo = async () => {
    getAnnonceShow(companyId, id)
    .then((data)=> setJobInfo(data) )
    .catch((error)=> console.error('There was an error:', error) )
  };

  const getBannerImg = async () => {
    setIsBannerLoading(true);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    const apiServerUrl = process.env.REACT_APP_DOWNLOAD_URL + '/download';
    const directory = "master";

    try {
      const url = `${apiServerUrl}/${directory}/${tenant.banner.storedFilename}`;
      const response = await axios.get(url, {
        responseType: 'blob',
      });

      const localUrl = URL.createObjectURL(response.data);
      setBannerImg(localUrl);
    } catch (error) {
      console.error('Download error:', error);
    } finally {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
      setIsBannerLoading(false);
    }
  };

  const getLastJobs = async ()=>{
    setIsSuggestionLoading(true);
    const formData = new FormData();
    formData.append('filter', new Blob([JSON.stringify({
      keyWord: "",
    })], { type: 'application/json' }));

    getAllAnnonces(companyId, formData)
    .then((data)=>{
      setLastJobs(data.content.filter((it)=> parseInt(it.id) !== parseInt(id) ));
    })
    .catch((error)=> console.error('There was an error:', error) )
    .finally(()=> setIsSuggestionLoading(false) )
  }

  useEffect(()=>{
    getJobInfo();
    getLastJobs();
  },[location]);

  useEffect(() => {
    if(Object.keys(tenant).length > 0){
      getBannerImg();
    }
  }, [tenant])
  

  if(Object.keys(jobInfo).length === 0 || isBannerLoading ){
    return(
      <div className='min-h-[80vh] flex items-center justify-center' >
        <div className='loading' >
          <div className='box' />
        </div>
      </div>
    )
  }

  return (
    <motion.div
      ref={mainDivRef}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
      // transition={{ duration: 0.3 }}
      className='mainSec pb-10' 
    >
      <div >
        <div className='absolute left-0 top-0 w-full' style={{zIndex: -1, height: firstContainerHeight +80}} >
          <img src={bannerImg} className='w-full h-full object-cover' ref={bgImgRef} style={{opacity: isImgLoaded ? 1 : 0}} />
        </div>
        {/* {
          window?.innerWidth > 769 ?  
          <div className='absolute left-0 w-full' style={{zIndex: -1, top: -bgImgHeight+firstContainerHeight+80, height: firstContainerHeight+80}} >
            <img src={bannerImg} className='w-full h-auto contain' ref={bgImgRef} style={{opacity: isImgLoaded ? 1 : 0}} />
          </div>
        :
          <div className='absolute left-0 w-full' style={{zIndex: -1, top: 0, height: firstContainerHeight+80}} >
            <img src={bannerImg} className='w-full h-full contain ' ref={bgImgRef} style={{opacity: isImgLoaded ? 1 : 0}} />
          </div>
        } */}
        <div ref={firstContainerRef} className='py-10 pt-16 flex items-center justify-between gap-4 flex-col lg:flex-row ' >
          <div className='px-4 lg:p-0 lg:w-[78%] w-full'>
            <div className='flex items-center gap-4'>
              <button onClick={() => navigate(-1)}
                      className='group border border-white p-1.5 hover:border-brome rounded-full'>
                <FiChevronLeft size={20} className='text-sm text-white group-hover:text-brome'/>
              </button>
              <h1
                className='text-white font-bold text-2xl lg:text-4xl lg:text-start lg:leading-[3rem]'>{jobInfo.titre}</h1>
            </div>
            {/* <div className='flex gap-4 mt-3 lg:justify-start justify-center' >
              <p className='flex items-center gap-1 text-sm text-white' > <FiMapPin /> {jobInfo.ville.libelle} </p>
              <p className='flex items-center gap-1 text-sm text-white' > <FiFileText /> {jobInfo.typeContrats?.map(it=> it.libelle)} </p>
              <p className='flex items-center gap-1 text-sm text-white' > <FiBriefcase /> {jobInfo.niveaux?.map(it=> it.libelle)} </p>
              <p className='flex items-center gap-1 text-sm text-white' > <FiCalendar /> {moment(jobInfo.datePublication).locale('fr').calendar()} </p>
            </div> */}

            <div className='flex gap-[2%] mt-3 lg:justify-start'>
              <div className='flex items-center gap-1 text-sm text-white max-w-[23%]'>
                <div className='w-5 h-5 flex items-center justify-center'><FiMapPin size={15}/></div>
                <span title={jobInfo.ville.libelle} className='truncate'>{jobInfo.ville.libelle}</span>
              </div>
              <div className='flex items-center gap-1 text-sm text-white max-w-[23%]'>
                <div className='w-5 h-5 flex items-center justify-center'><FiFileText size={15}/></div>
                <span title={jobInfo.typeContrats?.map(it => it.libelle).join(', ')}
                      className='truncate'>{jobInfo.typeContrats?.map(it => it.libelle).join(', ')} </span>
              </div>
              <div className='flex items-center gap-1 text-sm text-white max-w-[23%]'>
                <div className='w-5 h-5 flex items-center justify-center'><FiBriefcase size={15}/></div>
                <span title={jobInfo.niveaux?.map(it => it.libelle).join(', ')}
                      className='truncate'>{jobInfo.niveaux?.map(it => it.libelle).join(', ')} </span>
              </div>
              <div className='flex items-center gap-1 text-sm text-white max-w-[23%]'>
                <div className='w-5 h-5 flex items-center justify-center'><FiCalendar size={15}/></div>
                <span title={moment(jobInfo.datePublication).locale('fr').calendar()}
                      className='truncate'>{moment(jobInfo.datePublication).locale('fr').calendar()} </span>
              </div>
            </div>
          </div>
          <div className='flex gap-2 relative lg:w-[22%] w-full lg:justify-end justify-center items-center'>
            {
              applicatedJobs.includes(jobInfo.id) ?
                <button disabled
                        className='text-sm font-semibold bg-brome px-8 py-2.5 w-max h-max rounded-lg text-white hover:shadow-lg hover:bg-brome-hover'>
                  {t("JOB.ALREADY_APPLICATED")}
                </button>
                :
                isLogged ?
                  <button onClick={() => openSubmitModal()}
                          className='text-sm font-semibold bg-brome px-8 py-2.5 w-max h-max rounded-lg text-white hover:shadow-lg hover:bg-brome-hover'>
                    {t("JOB.APPLY_NOW_BTN")}
                  </button>
                  :
                  <Link to={`/${companyId}/form/${id}`}
                        className='text-sm text-center font-semibold bg-brome px-8 py-2.5 w-max h-max rounded-lg text-white hover:shadow-lg hover:bg-brome-hover'>
                    {t("JOB.INTERESTED_BTN")}
                  </Link>
            }
            <div className='relative'>
              <div onClick={() => setIsShareMenuOpen(!isShareMenuOpen)}
                   className='border px-2.5 py-2.5 h-max rounded-lg border-white text-white hover:border-brome hover:text-brome cursor-pointer'>
                <FiShare2/>
              </div>
              {isShareMenuOpen &&
                <div ref={shareMenuRef}
                     className='absolute top-11 px-1.5 py-1.5 right-0 bg-white customShadow rounded-lg'>
                  <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                     target="_blank" rel="noopener noreferrer" className='border rounded-full w-7 h-7 flex items-center justify-center mb-1 hover:border-brome hover:text-brome'>
                    <FiFacebook size={18} />
                  </a>
                  <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`} target="_blank" rel="noopener noreferrer" className='border rounded-full w-7 h-7 flex items-center justify-center mb-1 hover:border-brome hover:text-brome'>
                    <FiLinkedin size={18} />
                  </a>
                  <a href={`https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`} target="_blank" rel="noopener noreferrer" className='border rounded-full w-7 h-7 flex items-center justify-center hover:border-brome hover:text-brome'>
                    <MdOutlineWhatsapp size={18} />
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
        <div className='flex gap-4 mt-5 text-sm lg:flex-row flex-col-reverse px-4 lg:p-0' >
          <div className='lg:w-2/3 w-full border rounded-lg p-4' >
            <h3 className='font-semibold text-base mb-2' >{t("JOB.DESCRIPTION_TITLE")}</h3>
            <div className='descriptif' dangerouslySetInnerHTML={{ __html: jobInfo.descriptif }} />
          </div>
          <div className='lg:w-1/3 w-full border rounded-lg p-4' >
            <h3 className='font-semibold text-base mb-2' >{t("JOB.INFO_TITLE")}</h3>
            <div className='flex flex-col gap-3' >
              <div className='flex justify-between items-center gap-0.5' >
                <p className='text-gray-400 font-semibold text-sm flex items-center gap-1.5' >
                  <FiCalendar/> {t("JOB.INFORMATIONS.DATE_DEMARAGE")}
                </p>
                <p>{jobInfo.dateDesactivation ? moment(jobInfo.dateDemarageCible).locale('fr').calendar() : "Non définie" }</p>
              </div>
              <div className='flex justify-between items-center gap-0.5' >
                <p className='text-gray-400 font-semibold flex items-center gap-1.5' >
                  <FiCalendar/> {t("JOB.INFORMATIONS.DATE_LIMITE")}
                </p>
                <p>{jobInfo.dateLimiteCandidature ? moment(jobInfo.dateLimiteCandidature).locale('fr').calendar() : "Non définie" }</p>
              </div>
              <div className='flex justify-between items-center gap-1' >
                <p className='text-gray-400 font-semibold flex items-center gap-1.5 max-w-[50%]' >
                  <FiBriefcase className='w-5' /> {t("JOB.INFORMATIONS.EXPERIENCE_PRO")}
                </p>
                <p className='max-w-[50%]' >{jobInfo.niveaux?.map(it=> it.libelle).join(', ')}</p>
              </div>
              <div className='flex justify-between items-center gap-1' >
                <p className='text-gray-400 font-semibold flex items-center gap-1.5 max-w-[50%]' >
                  <FiFileText/> {t("JOB.INFORMATIONS.TYPE_CONTRACT")}
                </p>
                <p className='max-w-[50%]' >{jobInfo.typeContrats?.map(it=> it.libelle).join(', ')}</p>
              </div>
              <div className='flex justify-between items-center gap-1' >
                <p className='text-gray-400 font-semibold flex items-center gap-1.5 max-w-[50%]' >
                  <FiGrid/> {t("JOB.INFORMATIONS.DOMAINE")}
                </p>
                <p className='max-w-[50%]' >{jobInfo.domaines?.map(it=> it.libelle).join(', ')}</p>
              </div>
              <div className='flex justify-between items-center gap-0.5' >
                <p className='text-gray-400 font-semibold flex items-center gap-1.5' >
                  <FiMapPin/> {t("JOB.INFORMATIONS.CITY")}
                </p>
                <p>{jobInfo.ville.libelle}</p>
              </div>
              <div className='flex justify-between items-center gap-0.5' >
                <p className='text-gray-400 font-semibold flex items-center gap-1.5' >
                  <FiMap/> {t("JOB.INFORMATIONS.COUNTRY")}
                </p>
                <p>Maroc</p> 
              </div>
              {/* <p className='text-gray-500 font-semibold' >Date desactivation</p>
              {jobInfo.dateDesactivation ? moment(jobInfo.dateDesactivation).locale('fr').calendar() : "Non définie" }<br/> */}
            </div>
          </div>
        </div>
        <div className='py-4 border-y mt-5 relative' >
          <div className='absolute top-[-10px] left-4 bg-white px-3 text-sm font-semibold text-gray-500' >
            {t("JOB.SUGGESTIONS.TITLE")}
          </div>
          <div className=' relative min-h-[7rem]' >
            {
              isSuggestionLoading ? <div className='loader'/>
                :
                // <div className='w-full grid grid-cols-5' >
                //   {
                //     lastJobs.map((it, index)=>
                //       index < 5 &&
                //       <motion.div key={it.id} whileHover={{ scale: 1.03 }} className='suggestion-list-item bg-white px-3 py-3 cursor-pointer min-h-[7rem]' >
                //         <Link onClick={()=> setJobInfo({}) } to={`/${companyId}/job/${it.id}`} className='flex flex-col justify-between h-full' >
                //           <p className='font-semibold mb-2' >{it.titre}</p>
                //           <div className='flex gap-2 justify-between text-xs text-gray-500 ' >
                //             <div className='flex items-center gap-1 w-1/2'>
                //               <FiMapPin className='w-[15%]' />
                //               <span title={it.ville.libelle} className='truncate w-[85%]' >{it.ville.libelle}</span>
                //             </div>
                //             <div className='flex items-center gap-1 w-1/2'>
                //               <FiBriefcase className='w-[15%]' />
                //               <span title={it.niveaux?.map(it=> it.libelle).join(', ')} className='truncate w-[85%]' >{it.niveaux?.map(it=> it.libelle).join(', ')}</span>
                //             </div>
                //           </div>
                //         </Link>
                //       </motion.div>
                //     )
                //   }
                // </div>
                <div className='w-full flex overflow-x-auto lg:overflow-x-hidden overflow-y-hidden scrollbar-hide'>
                  {
                    lastJobs.map((it, index) =>
                      index < 5 &&
                      <motion.div key={it.id} whileHover={{scale: 1.03}}
                                  className='suggestion-list-item bg-white px-3 py-3 cursor-pointer min-h-[7rem] w-[250px]'>
                        <Link onClick={() => setJobInfo({})} to={`/${companyId}/job/${it.id}`}
                              className='flex flex-col justify-between h-full'>
                          <p className='font-semibold mb-2'>{it.titre}</p>
                          <div className='flex gap-2 justify-between text-xs text-gray-500 '>
                            <div className='flex items-center gap-1 w-1/2'>
                              <FiMapPin className='w-[15%]'/>
                              <span title={it.ville.libelle} className='truncate w-[85%]'>{it.ville.libelle}</span>
                            </div>
                            <div className='flex items-center gap-1 w-1/2'>
                              <FiBriefcase className='w-[15%]'/>
                              <span title={it.niveaux?.map(it => it.libelle).join(', ')}
                                    className='truncate w-[85%]'>{it.niveaux?.map(it => it.libelle).join(', ')}</span>
                            </div>
                          </div>
                        </Link>
                      </motion.div>
                    )
                  }
                </div>
            }
          </div>
        </div>
      </div>
      <Modal isOpen={isSubmitModalOpen} close={closeSubmitModal}>
        <SubmitApplicationModal
          jobInfo={jobInfo}
          companyId={companyId}
          getApplicatedJobs={getApplicatedJobs}
          openCompleteSignUpModal={openCompleteSignUpModal}
        />
      </Modal>
      <CompleteSignUpModal isOpen={isCompleteSignUpModalOpen} close={closeCompleteSignUpModal}/>
    </motion.div>
  )
}
