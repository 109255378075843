import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { MdOutlineWhatsapp } from "react-icons/md";
import { FiSearch, FiBriefcase, FiChevronDown, FiChevronUp, FiMapPin, FiCalendar, FiFileText, FiShare2, FiFacebook, FiLinkedin, FiFilter, FiXCircle, FiAlertTriangle, FiCheck } from 'react-icons/fi'; 
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import * as sanitizeHtml from 'sanitize-html';

import { UserContext } from '../Contexts/UserContext';
import homeBg from '../assets/homeBg.png';
import MobileFilterModal from './MobileFilterModal';
import CompleteSignUpModal from '../Auth/CompleteSignUpModal';
import { getAnnoncesFilters, getAnnoncesPage, getAnnoncesSearch, getAnnoncesUrgents } from '../services/annoncesService';

const jobsScrollVariants = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  hidden: { opacity: 0, y: 50 }, 
};
const skeletonVariant = {
  animate: {
    opacity: [0.5, 1, 0.5],
    transition: {
      duration: 2,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "loop",
    },
  },
};  

function JobContainer({it, applicatedJobs}){

  let { companyId } = useParams();
  const { t } = useTranslation();
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);

  const shareUrl = `https://job.lookahr.com/#/${companyId}/${it.id}` ;
  const whatsappMessage = `Regarde cette annonce: https://job.lookahr.com/#/${companyId}/${it.id}`;

  return(
    <motion.div
      key={it.id}
      variants={jobsScrollVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      className={cx('group border rounded-lg py-2 px-4 flex cursor-pointer hover:bg-gray-100 mb-4 relative', {'bg-gray-100': applicatedJobs.includes(it.id)})}
      onMouseLeave={()=> setIsShareMenuOpen(false) }
    >
      { applicatedJobs.includes(it.id) &&
        <div className='absolute top-[-8px] right-12 text-white text-xs font-semibold bg-brome rounded-full px-2 py-0.5' >
          {t("JOB.ALREADY_APPLICATED")}
        </div>
      }
      <Link to={`/${companyId}/job/${it.id}`} className='flex w-full' >
        <div className='w-5/6'>
          <p className='text-lg font-semibold lg:mb-1 mb-2 group-hover:text-brome'>{it.titre}</p>
          <ul className='lg:flex grid grid-cols-2 lg:gap-4 gap-2 mb-3 text-sm text-gray-500' > 
            <li className='flex items-center gap-1 lg:max-w-[25%]' >
              <FiMapPin className='min-w-[22px]' />
              <p title={it.ville.libelle} className='truncate'>{it.ville.libelle}</p>
            </li>
            <li className='flex items-center gap-1 lg:max-w-[25%]' >
              <FiFileText className='min-w-[22px]' />
              <p title={it.typeContrats?.map(it=> it.libelle).join(', ')} className='truncate'>{it.typeContrats?.map(it=> it.libelle).join(', ')}</p>
            </li>
            <li className='flex items-center gap-1 lg:max-w-[25%]' >
              <FiBriefcase className='min-w-[22px]' />
              <p title={it.niveaux?.map(it=> it.libelle).join(', ')} className='truncate'>{it.niveaux?.map(it=> it.libelle).join(', ')}</p>
            </li>
            <li className='flex items-center gap-1 lg:max-w-[25%]' >
              <FiCalendar className='min-w-[22px]' />
              <p title={moment(it.datePublication).locale('fr').calendar()} className='truncate'>{moment(it.datePublication).locale('fr').calendar()}</p>
            </li>
          </ul>
          {/* <div className='text-gray-700 text-sm line-clamp-4' dangerouslySetInnerHTML={{ __html: it.descriptif }} /> */}
          <div className='text-gray-700 text-sm line-clamp-4' >
            { sanitizeHtml(it.descriptif, { allowedTags: [], allowedAttributes: {} })}
          </div>
        </div>
        <div className='w-1/6 flex flex-col justify-between items-end py-1'>
          <div onMouseEnter={()=> setIsShareMenuOpen(true) } className='hidden lg:block border border-white rounded-full hover:border-brome hover:text-brome p-1'>
            <FiShare2 />
          </div>
          {/* <Link to={"/form/"+it.id} className='border rounded-lg border-brome text-xs font-semibold text-brome px-2.5 py-1.5 hover:bg-brome hover:text-white' >
            postuler
          </Link> */}
        </div>
      </Link>
      {
        isShareMenuOpen &&
        <div onMouseLeave={() => setIsShareMenuOpen(false)} className='absolute z-10 top-10 p-1 right-3.5 bg-white customShadow rounded-full'>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`} target="_blank" rel="noopener noreferrer" className='border rounded-full w-6 h-6 flex items-center justify-center mb-1 hover:border-brome hover:text-brome'>
            <FiFacebook size={14} />
          </a>
          <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}`} target="_blank" rel="noopener noreferrer" className='border rounded-full w-6 h-6 flex items-center justify-center mb-1 hover:border-brome hover:text-brome'>
            <FiLinkedin size={14} />
          </a>
          <a href={`https://wa.me/?text=${encodeURIComponent(whatsappMessage)}`} target="_blank" rel="noopener noreferrer" className='border rounded-full w-6 h-6 flex items-center justify-center hover:border-brome hover:text-brome'>
            <MdOutlineWhatsapp size={14} />
          </a>
        </div>
      }
    </motion.div>
  )
}

function CustomCheckBox({isChecked, check, type, id}){
  return(
    <div className={cx('border rounded w-4 h-4 flex items-center justify-center cursor-pointer', {'bg-brome': isChecked})} onClick={()=> check(type, id) } >
      { isChecked && <FiCheck color='#fff' size={14} />}
    </div>
  )
}

export function FiltersContainer({filtersData, filters, setFilters, isLoading}){

  const { t } = useTranslation();

  const [isCitiesFilterOpen, setIsCitiesFilterOpen] = useState(false);
  const [isDomainesFilterOpen, setIsDomainesFilterOpen] = useState(false);
  const [isNiveauFilterOpen, setIsNiveauFilterOpen] = useState(false);
  const [isContractTypeFilterOpen, setIsContractTypeFilterOpen] = useState(false);

  const filterMenuVariants = {
    open: { opacity: 1, height: 'auto' },
    closed: { opacity: 1, height: '80px' }
  };

  const toggleFilter = (filterCategory, id) => {
    const currentFilters = filters[filterCategory];
    const isIdPresent = currentFilters.includes(id);
    const updatedFilters = isIdPresent
      ? currentFilters.filter(item => item !== id) // Remove id
      : [...currentFilters, id]; // Add id

    setFilters({
      ...filters,
      [filterCategory]: updatedFilters,
    });
  };

  const checkInput = (type, id)=>{
    toggleFilter(type, id);
  }

  if(isLoading){
    return(
      <>
        <div className='text-ls sticky top-0 pt-3 px-4 z-20 bg-white font-semibold mb-3' >
          <div className='border-b pb-1.5' >
            {t("HOME.FILTERS.TITLE")}
          </div>
        </div>
        <div className='pb-3 px-4' >
          <div >
            <div className='flex items-center justify-between mb-3' >
              <motion.div variants={skeletonVariant} animate="animate" className='h-5 bg-gray-200 w-2/3'/>
              <FiChevronDown size={16} />
            </div>
            <motion.div variants={skeletonVariant} animate="animate" className='mb-2 h-3 bg-gray-200 w-1/3'/>
            <motion.div variants={skeletonVariant} animate="animate" className='mb-2 h-3 bg-gray-200 w-1/2'/>
            <motion.div variants={skeletonVariant} animate="animate" className='mb-2 h-3 bg-gray-200 w-1/3'/>
          </div>
        </div>
      </>
    )
  }

  return(
    <>
      <div className='text-ls sticky top-0 pt-3 px-4 z-20 bg-white font-semibold mb-3' >
        <div className='border-b pb-1.5' >
          {t("HOME.FILTERS.TITLE")}
        </div>
      </div>
      <div className='pb-3 px-4' >
        <div className='mb-2 relative' >
          <div className='flex items-center justify-between mb-2 cursor-pointer' onClick={()=> setIsNiveauFilterOpen(!isNiveauFilterOpen) } >
            <p className='text-sm font-semibold text-gray-500' >{t("HOME.FILTERS.BY_EXPERIENCE")}</p>
            {
              isNiveauFilterOpen 
              ? <FiChevronUp size={16} />
              : <FiChevronDown size={16} />
            }
          </div>
          {!isNiveauFilterOpen && <div className="fadeOverlay"></div>}
          <motion.div
            initial="closed"
            animate={isNiveauFilterOpen ? "open" : "closed"}
            variants={filterMenuVariants}
            transition={{ duration: 0.5 }}
            className="overflow-hidden px-1"
          >
            {filtersData?.niveaux?.map((it) => (
              <div key={it.id} className='group flex items-center gap-2 mb-2'>
                <CustomCheckBox 
                  isChecked={filters.niveaux.includes(it.id)} 
                  check={checkInput}
                  type="niveaux"
                  id={it.id}
                />
                <label title={it.libelle} onClick={() => toggleFilter('niveaux', it.id)} className='text-sm max-w-[80%] cursor-pointer group-hover:text-brome truncate'>
                  {it.libelle}
                </label>
              </div>
            ))}
          </motion.div>
        </div>
        <div className='mb-2 relative' >
          <div className='flex items-center justify-between mb-2 cursor-pointer' onClick={()=> setIsDomainesFilterOpen(!isDomainesFilterOpen) } >
            <p className='text-sm font-semibold text-gray-500' >{t("HOME.FILTERS.BY_DOMAINE")}</p>
            {
              isDomainesFilterOpen 
              ? <FiChevronUp size={16} />
              : <FiChevronDown size={16} />
            }
          </div>
          {!isDomainesFilterOpen && <div className="fadeOverlay"></div>}
          <motion.div
            initial="closed"
            animate={isDomainesFilterOpen ? "open" : "closed"}
            variants={filterMenuVariants}
            transition={{ duration: 0.5 }}
            className="overflow-hidden px-1"
          >
            {filtersData?.domaines?.map((it) => (
              <div key={it.id} className='group flex items-center gap-2 mb-2'>
                <CustomCheckBox 
                  isChecked={filters.domaines.includes(it.id)} 
                  check={checkInput}
                  type="domaines"
                  id={it.id}
                />
                <label title={it.libelle} onClick={() => toggleFilter('domaines', it.id)} className='text-sm max-w-[80%] cursor-pointer group-hover:text-brome truncate'>
                  {it.libelle}
                </label>
              </div>
            ))}
          </motion.div>
        </div>
        <div className='mb-2 relative' >
          <div className='flex items-center justify-between mb-2 cursor-pointer' onClick={()=> setIsContractTypeFilterOpen(!isContractTypeFilterOpen) } >
            <p className='text-sm font-semibold text-gray-500' >{t("HOME.FILTERS.BY_TYPECONTRACT")}</p>
            {
              isContractTypeFilterOpen 
              ? <FiChevronUp size={16} />
              : <FiChevronDown size={16} />
            }
          </div>
          {!isContractTypeFilterOpen && <div className="fadeOverlay"></div>}
          <motion.div
            initial="closed"
            animate={isContractTypeFilterOpen ? "open" : "closed"}
            variants={filterMenuVariants}
            transition={{ duration: 0.5 }}
            className="overflow-hidden px-1"
          >
            {filtersData?.type_contrats?.map((it) => (
              <div key={it.id} className='group flex items-center gap-2 mb-2'>
                <CustomCheckBox 
                  isChecked={filters.typeContrats.includes(it.id)} 
                  check={checkInput}
                  type="typeContrats"
                  id={it.id}
                />
                <label title={it.libelle} onClick={() => toggleFilter('typeContrats', it.id)} className='text-sm max-w-[80%] cursor-pointer group-hover:text-brome truncate' >
                  {it.libelle}
                </label>
              </div>
            ))}
          </motion.div>
        </div>
        <div className='relative' >
          <div className='flex items-center justify-between mb-2 cursor-pointer' onClick={()=> setIsCitiesFilterOpen(!isCitiesFilterOpen) } >
            <p className='text-sm font-semibold text-gray-500' >{t("HOME.FILTERS.BY_CITY")}</p>
            {
              isCitiesFilterOpen 
              ? <FiChevronUp size={16} />
              : <FiChevronDown size={16} />
            }
          </div>
          {!isCitiesFilterOpen && <div className="fadeOverlay"></div>}
          <motion.div
            initial="closed"
            animate={isCitiesFilterOpen ? "open" : "closed"}
            variants={filterMenuVariants}
            transition={{ duration: 0.5 }}
            className="overflow-hidden px-1"
          >
            {filtersData?.villes?.map((it) => (
              <div key={it.id} className='group flex items-center gap-2 mb-2'>
                <CustomCheckBox 
                  isChecked={filters.ville.includes(it.id)} 
                  check={checkInput}
                  type="ville"
                  id={it.id}
                />
                <label title={it.libelle} onClick={() => toggleFilter('ville', it.id)} className='text-sm max-w-[80%] cursor-pointer group-hover:text-brome truncate' >
                  {it.libelle}
                </label>
              </div>
            ))}
          </motion.div>
        </div>
        {/*<div>
          <div className='flex items-center justify-between mb-2 cursor-pointer' onClick={()=> setIsAnnonceTypeFilterOpen(!isAnnonceTypeFilterOpen) } >
            <p className='text-sm font-semibold text-gray-500' >{t("HOME.FILTERS.BY_ANNONCETYPE")}</p>
            {
              isAnnonceTypeFilterOpen 
              ? <FiChevronUp size={16} />
              : <FiChevronDown size={16} />
            }
          </div>
          <motion.div
            initial="closed"
            animate={isAnnonceTypeFilterOpen ? "open" : "closed"}
            variants={filterMenuVariants}
            transition={{ duration: 0.5 }}
            className="overflow-hidden px-1"
          >
            {filtersData?.type_annonces?.map((it) => (
              <div key={it.id} className='group flex items-center gap-2 mb-2'>
                <CustomCheckBox 
                  isChecked={filters.typeAnnonce.includes(it.id)} 
                  check={checkInput}
                  type="typeAnnonce"
                  id={it.id}
                />
                <label onClick={() => toggleFilter('typeAnnonce', it.id)} className='text-sm max-w-[80%] cursor-pointer group-hover:text-brome truncate' >
                  {it.libelle}
                </label>
              </div>
            ))}
          </motion.div>
        </div>*/}
      </div>
    </>
  )
}

export default function Home() {

  const { t } = useTranslation();
  let { companyId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { tenant, applicatedJobs } = useContext(UserContext);

  const firstContainerRef = useRef(null);
  const searchBarRef = useRef(null);
  const jobsContainerRef = useRef(null);
  const titleSearchMenuRef = useRef(null);
  const domaineSearchMenuRef = useRef(null);

  const currPageRef = useRef(1);
  const isLastPageRef = useRef(false);

  const [currPage, setCurrPage] = useState(1);

  const [jobs, setJobs] = useState([]);
  const [filtersData, setFiltersData] = useState({});
  const [bannerImg, setBannerImg] = useState("");
  const [titleSearchSuggestionData, setTitleSearchSuggestionData] = useState([]);

  const [filters, setFilters] = useState({
    typeContrats: [],
    domaines: [],
    ville: [],
    niveaux: [],
    typeAnnonce: [],
  });

  const [jobsContainerLeftPosition, setJobsContainerLeftPosition] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [isBannerLoading, setIsBannerLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [isFiltersLoading, setIsFiltersLoading] = useState(true);
  const [firstContainerHeight, setFirstContainerHeight] = useState(0);
  const [titleSearchInput, setTitleSearchInput] = useState("");
  const [domaineSearchInput, setDomaineSearchInput] = useState("");
  const [isTitleSeachMenuOpen, setIsTitleSearchMenuOpen] = useState(false);
  const [isDomaineSearchMenuOpen, setIsDomaineSearchMenuOpen] = useState(false);
  const [isTitleShowen, setIsTitleShowen] = useState(false);
  const [isMobileFilterModalOpen, setIsMobileFilterModalOpen] = useState(false);
  const [urgentData, setUrgentData] = useState([]);
  const [isCompleteSignUpModalOpen, setIsCompleteSignUpModalOpen] = useState(false);

  const handleInputChange = (value, type)=>{
    switch (type) {
      case "title":
        setTitleSearchInput(value);
        setIsTitleSearchMenuOpen(value.length > 0);
        break;
      case "domaine":
        setDomaineSearchInput(value);
        setIsDomaineSearchMenuOpen(value.length > 0);
        break;
      default:
        break;
    }
  }

  const openMobileFilterModal = ()=>{
    // document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsMobileFilterModalOpen(true);
  }
  const closeMobileFilerModal = ()=>{
    // document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsMobileFilterModalOpen(false);
  }

  const openCompleteSignUpModal = ()=>{
    // document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsCompleteSignUpModalOpen(true);
  }
  const closeCompleteSignUpModal = ()=>{
    // document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsCompleteSignUpModalOpen(false);
    navigate(location.pathname, { state: {} });
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setFirstContainerHeight(entry.target.clientHeight);
      }
    });

    if (firstContainerRef.current) {
      resizeObserver.observe(firstContainerRef.current);
    }

    return () => {
      if (firstContainerRef.current) {
        resizeObserver.unobserve(firstContainerRef.current);
      }
    };
  }, [location]);
 
  useEffect(()=>{
    setTimeout(() => {
      setIsTitleShowen(true);
    }, 20);
    setJobsContainerLeftPosition(jobsContainerRef.current.getBoundingClientRect().left)
  },[]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      if( currentScrollPosition > firstContainerHeight-40){
        setIsFixed(true);
      }else{
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [firstContainerHeight]);

  const handleClickOutside = (event) => {    
    if (event.target.classList.contains('suggestion')) return ;
    if (titleSearchMenuRef.current && !titleSearchMenuRef.current.contains(event.target)) {
      setIsTitleSearchMenuOpen(false);
    }
    if (domaineSearchMenuRef.current && !domaineSearchMenuRef.current.contains(event.target)) {
      setIsDomaineSearchMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (location.hash && location.hash === "#jobs") {
      jobsContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    console.log(location.state?.isFromSignUp);
    if(location.state?.isFromSignUp){
      openCompleteSignUpModal();
    }

  }, [location]);

  const getFilters = async () => {
    setIsFiltersLoading(true);

    getAnnoncesFilters(companyId)
    .then((data)=> setFiltersData(data) )
    .catch((error)=> console.error('There was an error fetching the tenants:', error) )
    .finally(()=>{
      setTimeout(() => {
        setIsFiltersLoading(false);
      }, 500);
    })
  };

  const getAnnounces = async () => {
    setIsLoading(true);
    isLastPageRef.current = false;
  
    const formData = new FormData();
    formData.append('filter', new Blob([JSON.stringify({
      keyWord: titleSearchInput,
      ...filters
    })], { type: 'application/json' }));

    getAnnoncesPage(companyId, currPageRef.current, formData)
    .then((data)=>{
      setJobs(prevJobs => [...prevJobs, ...data.content]);
      isLastPageRef.current = data.last;
      currPageRef.current = currPageRef.current + 1;
    })
    .catch((error)=> console.error('There was an error:', error) )
    .finally(()=> setIsLoading(false) )
  };

  const getSearchResult = async (titleSearchText)=>{
    currPageRef.current = 1;
    isLastPageRef.current = false;
    setIsLoading(true);
  
    const formData = new FormData();
    formData.append('filter', new Blob([JSON.stringify({
      keyWord: titleSearchText !== undefined ? titleSearchText : titleSearchInput,
      ...filters
    })], { type: 'application/json' }));

    getAnnoncesSearch(companyId, formData)
    .then((data)=>{
      setJobs(data.content);
      isLastPageRef.current = data.last;
      currPageRef.current = currPageRef.current + 1;
    })
    .catch((error)=> console.error('There was an error:', error) )
    .finally(()=> setIsLoading(false) )
  }

  const getTitleSearchSuggestion = async (titleSearchText)=>{
    currPageRef.current = 1;
    isLastPageRef.current = false;
    setIsLoading(true);
  
    const formData = new FormData();
    formData.append('filter', new Blob([JSON.stringify({
      keyWord: titleSearchText !== undefined ? titleSearchText : titleSearchInput,
      ...filters
    })], { type: 'application/json' }));

    getAnnoncesSearch(companyId, formData)
    .then((data)=>{
      setTitleSearchSuggestionData(data.content);
    })
    .catch((error)=> console.error('There was an error:', error) )
    .finally(()=> setIsLoading(false) )
  }

  const getUrgentData = async ()=>{
    getAnnoncesUrgents(companyId)
    .then((data)=> setUrgentData(data) )
    .catch((error)=> console.error('There was an error:', error) )
  }

  useEffect(()=>{
    if(titleSearchInput.length > 0){
      getTitleSearchSuggestion();
    }else{
      setTitleSearchSuggestionData([]);
    }
  },[titleSearchInput])

  const getBannerImg = async () => {
    setIsBannerLoading(true);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    const apiServerUrl = process.env.REACT_APP_DOWNLOAD_URL + '/download';
    const directory = "master";

    try {
      const url = `${apiServerUrl}/${directory}/${tenant.banner.storedFilename}`;
      const response = await axios.get(url, {
        responseType: 'blob',
      });

      const localUrl = URL.createObjectURL(response.data);
      setBannerImg(localUrl);
    } catch (error) {
      console.error('Download error:', error);
    } finally {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
      setIsBannerLoading(false);
    }
  };

  useEffect(() => {
    if(Object.keys(tenant).length > 0){
      getBannerImg();
      getFilters();
      getUrgentData();
    }
    // window.addEventListener('scroll', handleScroll);
    // return () => window.removeEventListener('scroll', handleScroll);
  }, [tenant]);

  // const handleScroll = () => {
  //   if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isScrollLoading.current || isLastPageRef.current) {
  //     return;
  //   }
  //   getAnnounces();
  // };

  const handleKeyPress = (e, type) => {
    if (e.key === 'Enter') {
      console.log(`${type} search initiated`);
      getSearchResult();
      setIsTitleSearchMenuOpen(false);
      setIsDomaineSearchMenuOpen(false);
    }
  };

  const searchSuggestionClick = (text, type)=>{
    switch (type) {
      case "title":
        setTitleSearchInput(text);
        getSearchResult(text);
        setIsTitleSearchMenuOpen(false);
        break;
      default:
        break;
    }
  }

  useEffect(()=>{
    getSearchResult();
  },[filters]);

  useEffect(()=>{
    if(isBannerLoading){
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }else{
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  },[isBannerLoading]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='mainSec' 
    >
      {
        isBannerLoading &&
        <div className='min-h-[90vh] flex items-center justify-center relative z-50 bg-white' >
          <div className='loading' >
            <div className='box' />
          </div>
        </div>
      }
      <div className='absolute top-0 left-0 w-full' style={{zIndex: -1, height: firstContainerHeight}} >
        <img loading="lazy" src={bannerImg} className='w-full h-full contain' />
      </div>
      {/* <button onClick={()=> changeColor("#f08328") } >
        change color test
      </button> */}
      <div 
        ref={firstContainerRef} 
        className='w-full p-10 pb-[7.5rem] flex flex-col items-center justify-center h-max mb-10 lg:mb-0' 
      >
        <h4 className='mb-6 text-white font-semibold' >
          {t("HOME.TITLE_2")}
        </h4>
        <h1 className='lg:text-[3.5rem] text-3xl font-bold text-center lg:leading-[4.5rem] leading-[2.8rem] lg:w-[70vw] w-[95vw] mb-10 text-white' >
          <div dangerouslySetInnerHTML={{ __html: tenant.text }} />
          {/* {t("HOME.TITLE_1.PART_1")}
          <span className={cx({'border-b-2 border-brome': isTitleShowen})} >{t("HOME.TITLE_1.PART_2")}</span>
          {t("HOME.TITLE_1.PART_3")}
          <span className={cx({'border-b-2 border-brome': isTitleShowen})} >{t("HOME.TITLE_1.PART_4")}</span> */}
        </h1>
        {/* web search bar */}
        { firstContainerHeight > 0 &&
          <div 
            ref={searchBarRef} 
            className={cx('px-4 py-4 h-20 bg-white z-10 min-w-[50vw] customShadow rounded-lg lg:flex hidden gap-4 absolute translate-x-[-50%]')} 
            style={{left: '50%', top: firstContainerHeight-40}} 
          >
            <div className='flex gap-4 items-center pr-4 relative w-full' >
              <FiSearch size={22} color='#000' />
              <input
                placeholder={t("HOME.SEARCH_BAR.BY_TITLE")}
                className='appearance-none text-sm min-w-52 w-full'
                value={titleSearchInput}
                onChange={(e)=> handleInputChange(e.target.value, "title") }
                onKeyDown={(e) => handleKeyPress(e, 'title')}
              />
              {
                isTitleSeachMenuOpen &&            
                <div ref={titleSearchMenuRef} className='absolute h-max w-full bg-white top-16 left-0 z-20 customShadow rounded-lg py-2' >
                  <ul className='text-sm' >
                    {
                      titleSearchSuggestionData.map((it)=>(
                        <li key={it.id} onClick={()=> searchSuggestionClick(it.titre, 'title') } className='suggestion px-3 py-2 hover:bg-gray-100 cursor-pointer border-b' >{it.titre}</li>
                      ))
                    }
                  </ul>
                </div>
              }
            </div>
            { titleSearchInput.length > 0 &&
              <button onClick={()=> searchSuggestionClick('', 'title') } >
                <FiXCircle size={14} className='hover:text-brome' />
              </button>
            }
            <button onClick={()=> getSearchResult() } className='text-sm font-semibold bg-brome px-5 py-3 rounded-xl text-white hover:shadow-lg hover:bg-brome-hover' >
              {t("COMMON.SEARCH")}
            </button>
          </div>
        }
        {/* mobile search bar */}
        { firstContainerHeight > 0 &&
          <div 
            ref={searchBarRef} 
            // className={cx('px-4 py-4 w-[80%] bg-white z-10 customShadow rounded-lg flex flex-col lg:hidden gap-4', {'absolute': !isFixed, 'fixed': isFixed})} 
            // style={{ top: isFixed ? 20 : firstContainerHeight-40}} 
            className={cx('px-4 py-4 w-[80%] bg-white z-10 customShadow rounded-lg flex flex-col lg:hidden gap-4 absolute')} 
            style={{ top: firstContainerHeight-40}} 
          >
            <div className='flex gap-4 items-center relative w-full' >
              <FiSearch size={22} color='#000' />
              <input
                placeholder={t("HOME.SEARCH_BAR.BY_TITLE")}
                className='appearance-none text-sm w-full py-2'
                value={titleSearchInput}
                onChange={(e)=> handleInputChange(e.target.value, "title") }
                onKeyDown={(e) => handleKeyPress(e, 'title')}
              />
              {
                isTitleSeachMenuOpen &&            
                <div ref={titleSearchMenuRef} className='absolute h-max w-full bg-white top-10 left-0 z-20 customShadow rounded-lg py-2' >
                  <ul className='text-sm' >
                    {
                      titleSearchSuggestionData.map((it)=>(
                        <li key={it.id} onClick={()=> searchSuggestionClick(it.titre, 'title') } className='suggestion px-3 py-2 hover:bg-gray-100 cursor-pointer border-b' >{it.titre}</li>
                      ))
                    }
                  </ul>
                </div>
              }
            </div>
            <div className='flex gap-2' >
              <button onClick={()=> openMobileFilterModal() } className='text-sm font-semibold border border-brome px-3 py-3 rounded-xl text-brome hover:shadow-lg' >
                <FiFilter size={16} />
              </button>
              <button className='text-sm font-semibold bg-brome px-5 py-3 w-full rounded-xl text-white hover:shadow-lg hover:bg-brome-hover' >
                {t("COMMON.SEARCH")}
              </button>
            </div>
          </div>
        }
      </div>
      <div className='flex gap-8 relative lg:px-10 px-2 select-none ' >
        <div className='hidden lg:flex border rounded-ld w-1/4 rounded-lg sticky top-5 h-max max-h-[calc(100vh_-_3rem)] overflow-y-auto flex-col gap-2' >
          <FiltersContainer filtersData={filtersData} filters={filters} setFilters={setFilters} isLoading={isFiltersLoading} />
        </div>
        <div id='jobs' ref={jobsContainerRef} className='w-full lg:w-2/4 pb-5' >
          {
            jobs.map((it) => <JobContainer key={it.id} it={it} applicatedJobs={applicatedJobs} /> )
          }
          { isLoading &&
            <motion.div
            variants={jobsScrollVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            className='group border rounded-lg py-3 px-4 flex mb-4 relative'
            onClick={()=> getAnnounces() }
            >
              <div className='w-full'>
                  <motion.div variants={skeletonVariant} animate="animate" className='mb-2 h-5 bg-gray-200 w-2/3'/>
                  <div className='lg:flex grid grid-cols-2 gap-4 mb-3'>
                    <div className='flex items-center gap-1 text-sm text-gray-500'><FiMapPin /> <motion.div variants={skeletonVariant} animate="animate" className='h-4 bg-gray-200 w-16' /></div>
                    <div className='flex items-center gap-1 text-sm text-gray-500'><FiFileText /> <motion.div variants={skeletonVariant} animate="animate" className='h-4 bg-gray-200 w-14' /></div>
                    <div className='flex items-center gap-1 text-sm text-gray-500'><FiBriefcase /> <motion.div variants={skeletonVariant} animate="animate" className='h-4 bg-gray-200 w-12' /></div>
                    <div className='flex items-center gap-1 text-sm text-gray-500'><FiCalendar /> <motion.div variants={skeletonVariant} animate="animate" className='h-4 bg-gray-200 w-20' /></div>
                  </div>
                  <motion.div variants={skeletonVariant} animate="animate" className='mb-2 h-3 bg-gray-200 w-full'/>
                  <motion.div variants={skeletonVariant} animate="animate" className='mb-2 h-3 bg-gray-200 w-2/3'/>
              </div>
            </motion.div>
          }
          { !isLastPageRef.current &&
            <div className='w-full flex items-center justify-center' >
              <button onClick={()=> getAnnounces() } className='border px-5 py-2 rounded-lg font-bold text-sm hover:border-brome hover:text-brome' >Charger plus</button>
            </div>
          }
        </div>
        <div 
          // className='hidden lg:flex border rounded-ld w-1/4 customShadow rounded-lg sticky top-32 h-[calc(100vh_-_9rem)] overflow-y-auto flex-col gap-2' 
          className='hidden lg:flex border rounded-ld w-1/4 customShadow rounded-lg sticky top-5 h-[calc(100vh_-_3rem)] overflow-y-auto flex-col gap-2' 
        >
          <div className='text-ls sticky top-0 pt-3 px-4 z-20 bg-white font-semibold mb-3 text-[#D6323A] ' >
            <div className='border-b border-[#D6323A] pb-1.5 flex items-center gap-1.5' >
               {t("HOME.URGENT")}
            </div>
          </div>
          <div className='flex flex-col gap-3 pb-3 px-4' >
            {
              urgentData.map((it, index) =>
                index < 6 &&
                  <motion.div key={it.id} whileHover={{ scale: 1.03 }} className='border rounded-lg p-2 cursor-pointer hover:border-[#D6323A]' >
                    <Link to={`/${companyId}/job/${it.id}`} >
                      <p className='font-semibold mb-2' >{it.titre}</p>
                      <div className='flex gap-2 justify-between text-xs text-gray-500 ' >
                        <div className='flex items-center gap-1 w-1/2'>
                          <FiMapPin className='w-[15%]' /> 
                          <span title={it.ville.libelle} className='truncate w-[85%]' >{it.ville.libelle}</span>
                        </div>
                        <div className='flex items-center gap-1 w-1/2'>
                          <FiBriefcase className='w-[15%]' /> 
                          <span title={it.niveaux?.map(it=> it.libelle).join(', ')} className='truncate w-[85%]' >{it.niveaux?.map(it=> it.libelle).join(', ')}</span>
                        </div>
                      </div>
                    </Link>
                  </motion.div> 
              )
            }
          </div>
        </div>
      </div>
      <CompleteSignUpModal isOpen={isCompleteSignUpModalOpen} close={closeCompleteSignUpModal} />
      <MobileFilterModal isOpen={isMobileFilterModalOpen} close={closeMobileFilerModal} filtersData={filtersData} filters={filters} setFilters={setFilters} isLoading={isFiltersLoading} />
    </motion.div>
  )
}
