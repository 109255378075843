import axios from 'axios';
import { loginEvent } from '../Contexts/events';

const auth_API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const tenantId = localStorage.getItem('tenantId');
  if (!refreshToken) {
    console.error('No refresh token available. User must re-authenticate.');
    return null;
  }

  if (!tenantId) {
    console.error('No refresh tenantId available');
    return null;
  }

  const config = {
    headers: {
      'XTENANT-HEADER': tenantId,
    },
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/account/refreshtoken`, {
      refreshToken,
    }, config);
    const { accessToken, refreshToken: newRefreshToken } = response.data;
    localStorage.setItem('accessToken', accessToken);
    if (newRefreshToken) {
      localStorage.setItem('refreshToken', newRefreshToken);
    }
    return accessToken;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    return null;
  }
};

auth_API.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

auth_API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        localStorage.setItem('accessToken', newAccessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return auth_API(originalRequest);
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        loginEvent.emit("login", false); 
      }
    }
    return Promise.reject(error);
  }
);

export default auth_API;
