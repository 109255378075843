import './App.scss';
import './styles/loading.scss';
import { Suspense, useState, useEffect } from 'react';
import {
  createHashRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { UserProvider } from './Contexts/UserContext';
import { ErrorProvider } from './Contexts/ErrorContext';

import Companies from './Components/Companies';
import Root from './Components/Root';
import Home from './Components/Home';
import Job from './Components/Job';
import Form from './Components/Form';
import Applications from './Components/Applications';
import Login from './Auth/Login';
import SignUp from './Auth/SignUp';
import Account from './Components/Account';
import ErrorPage from './error-page';
import OauthRedirect from './Auth/OauthRedirect';

import SignUpOld from './Auth/SignUp_old';

const router = () => createHashRouter([
  {
    path: "/oauth",
    element: <OauthRedirect/>,
  },
  {
    path: "/",
    element: <Companies/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:companyId",
    element: <Root/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/:companyId",
        element: <Home />,
      },{
        path: "job/:id",
        element: <Job />,
      },{
        path: "form/:id",
        element: <Form/>
      },{
        path: "applications",
        element: <Applications/>
      },{
        path: "compte",
        element: <Account/>
      }
    ],
  },
  {
    path: "/:companyId/login",
    element: <Login/>,
  },
  {
    path: "/:companyId/sign-up",
    element: <SignUp/>,
  }
]);

function App() {

  return (
    <Suspense fallback="loading">
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <AnimatePresence>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GCP_AUTH_CLIENT_ID} >
            <UserProvider>
              <ErrorProvider>
                <RouterProvider router={router()} />
              </ErrorProvider>
            </UserProvider>
          </GoogleOAuthProvider>
        </AnimatePresence>
      </GoogleReCaptchaProvider>
    </Suspense>
  );
}

export default App;
