import React, {useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import { FiChevronLeft, FiChevronRight, FiEdit2, FiGrid, FiMenu, FiTable, FiUser, FiUserCheck } from 'react-icons/fi';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import auth_API from '../services/api';

import moment from 'moment';
import 'moment/locale/fr';

import FullModal from './FullModal';
import { cancelCandidature, getCandidaturesPage } from '../services/candidatureService';

function TableView({data, companyId, fetchAgain, setIsApplicationsLoading}){

  const {t} = useTranslation();

  const cancelApplication = async (id)=>{
    setIsApplicationsLoading(true);

    cancelCandidature(companyId, id)
    .then((res)=> fetchAgain() )
    .catch((error)=> console.error('There was an error:', error) )
    .finally(()=> setIsApplicationsLoading(true) )

  }

  return(
    <div className="overflow-hidden border border-gray-200 rounded-lg mb-10">
      <table className="min-w-full border-collapse" id='my-applications-table' >
        <thead className="bg-gray-100">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("APPLICATIONS.TH.ANNONCE")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("APPLICATIONS.TH.DATE")}</th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">{t("APPLICATIONS.TH.STATUS")}</th>
            <th/>
          </tr>
        </thead>
        <tbody className="text-gray-700 text-sm">
          {
            data.map((it)=>(
              <tr key={it.id} >
                <td className="text-left py-3 px-2">
                  <Link to={`/${companyId}/job/${it.annonce.id}`} className='hover:underline cursor-pointer hover:text-brome' >
                    {it.annonce.titre}
                  </Link>
                </td>
                <td className="text-left py-3 px-2">{moment(it.creatAt).locale('fr').calendar()}</td>
                <td className="text-left py-3 px-2">
                  <div className={cx('w-max px-2 py-1 rounded-full text-xs', 
                    {'bg-sky-300 text-white': it.statutActuelCandidature === "SOUMISE", 
                    'bg-gray-200': it.statutActuelCandidature === "ANNULEE" ,
                    'bg-[#FEEBC7] text-yellow-700': it.statutActuelCandidature === "SELECTIONEE", 
                    'bg-[#FEEBC8] text-yellow-700': it.statutActuelCandidature === "ENVOYEE", 
                    // 'bg-[#D6323A]': it.statutActuelCandidature === "REFUSEE", 
                    'bg-orange-100 text-orange-600': it.statutActuelCandidature === "RETENUEENTRETIEN",
                    'bg-[#DBEDDB] text-green-700': it.statutActuelCandidature === "RETENUE",
                    })} >
                    {t(`APPLICATIONS.STATUS.${it.statutActuelCandidature}`)}
                  </div>
                </td>
                <td>
                  {
                    it.statutActuelCandidature !== "ANNULEE" &&
                    <button onClick={()=> cancelApplication(it.id) } className='text-xs border rounded-xl px-2 py-0.5 cursor-pointer text-gray-500 hover:border-[#D6323A] hover:text-[#D6323A]' >{t("APPLICATIONS.CANCEL_BTN")}</button>
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

function GridView({data, companyId, fetchAgain, setIsApplicationsLoading}){

  const {t} = useTranslation();

  const cancelApplication = async (id)=>{
    setIsApplicationsLoading(true);

    cancelCandidature(companyId, id)
    .then((res)=> fetchAgain() )
    .catch((error)=> console.error('There was an error:', error) )
    .finally(()=> setIsApplicationsLoading(true) )

  }

  return(
    <div className='grid lg:grid-cols-2 gap-4 mb-10' >
      {
        data.map((it)=>(
          <div key={it.id} className='border rounded-lg p-2' >
            <Link to={`/${companyId}/job/${it.annonce.id}`} className='hover:underline cursor-pointer hover:text-brome' >
              <p className='text-xl font-semibold mb-2' >{it.annonce.titre}</p>
            </Link>
            <div className='flex gap-2 items-center justify-between' >
                <div className='flex gap-2 items-center' >
                  <div className={cx('w-max px-2 py-1 rounded-full text-xs', 
                    {'bg-sky-300 text-white': it.statutActuelCandidature === "SOUMISE", 
                    'bg-gray-200': it.statutActuelCandidature === "ANNULEE" ,
                    'bg-[#FEEBC7] text-yellow-700': it.statutActuelCandidature === "SELECTIONEE", 
                    'bg-[#FEEBC8] text-yellow-700': it.statutActuelCandidature === "ENVOYEE", 
                    // 'bg-[#D6323A]': it.statutActuelCandidature === "REFUSEE", 
                    'bg-orange-100 text-orange-600': it.statutActuelCandidature === "RETENUEENTRETIEN",
                    'bg-[#DBEDDB] text-green-700': it.statutActuelCandidature === "RETENUE",
                    })} >
                    {t(`APPLICATIONS.STATUS.${it.statutActuelCandidature}`)}
                  </div>
                  <span className='text-xl' >•</span>
                  <p className='text-sm font-semibold text-gray-500' >{t("APPLICATIONS.SENDED_AT")} {moment(it.creatAt).locale('fr').calendar()}</p>
                </div>
                {
                  it.statutActuelCandidature !== "ANNULEE" &&
                  <button onClick={()=> cancelApplication(it.id) } className='text-xs border rounded-xl px-2 py-0.5 cursor-pointer text-gray-500 hover:border-[#D6323A] hover:text-[#D6323A]' >{t("APPLICATIONS.CANCEL_BTN")}</button>
                }
            </div>
          </div>
        ))
      }
    </div>
  )
}


export default function Applications() {

  const {t} = useTranslation();
  let { companyId } = useParams();
  const [menuType, setMenuType] = useState('table');
  const [applications, setApplications] =  useState([]);
  const [isApplicationsLoading, setIsApplicationsLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [sizeOfPage, setSizeOfPage] = useState(10);
  const [isLastPage, setIsLastPage] = useState(false);

  const getPageContent = ()=>{
    switch (menuType) {
      case 'table':
        return <TableView data={applications} companyId={companyId} fetchAgain={fetchCandidatures} setIsApplicationsLoading={setIsApplicationsLoading} />
      case 'grid':
        return <GridView data={applications} companyId={companyId} fetchAgain={fetchCandidatures} setIsApplicationsLoading={setIsApplicationsLoading} />
      default:
        break;
    }
  }

  const fetchCandidatures = async () => {
    setIsApplicationsLoading(true);
    
    const formData = new FormData();
    formData.append('filter', new Blob([JSON.stringify({
      keyWord: ''
    })], { type: 'application/json' }));

    getCandidaturesPage(companyId, page, sizeOfPage, formData)
    .then((data)=>{ 
      setApplications(data.content);
      setIsLastPage(data.last);
    })
    .catch((err)=> console.log(err) )
    .finally(()=> setIsApplicationsLoading(false) );

  };

  useEffect(() => {
    fetchCandidatures();
  }, [page, sizeOfPage]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='mainSec mt-5 px-4 lg:px-0 min-h-[70vh]' 
    >
      {/* <div onClick={()=> openFullModal() } className='flex items-center gap-10 border rounded-full pl-0.5 py-0.5 pr-4 w-max mb-4 cursor-pointer group' >
        <div className='flex items-center gap-3' >
          <div className='h-10 w-10 border rounded-full flex items-center justify-center' >
            <FiUser size={24} className='text-gray-500' />
          </div>
          <p className='font-bold group-hover:text-brome' >Adil Stifi</p>
        </div>
        <FiEdit2 size={14} className='text-gray-500 group-hover:text-brome' />
      </div> */}
      <div className='flex items-center justify-between' >
        <h1 className='md:text-3xl text-xl font-semibold text-gray-700' >{t("APPLICATIONS.TITLE")}</h1>
        <div className='flex border rounded-xl py-1 px-1 gap-1 relative' >
          <div className={cx('bg-brome transition-all duration-300 h-[calc(100%_-_0.5rem)] top-1 w-[calc(50%_-_0.42rem)] absolute rounded-lg', {'left-1': menuType === 'table', 'left-[53%]': menuType === 'grid'})} style={{zIndex: -1}} />
          <button onClick={()=> setMenuType("table") } className={cx('p-1.5 rounded-lg',{'text-white ': menuType === 'table', 'hover:bg-[#fbe9ea]': menuType === 'grid'})} >
            <FiTable size={22} />
          </button>
          <button onClick={()=> setMenuType("grid") } className={cx('p-1.5 rounded-lg',{'text-white': menuType === 'grid', 'hover:bg-[#fbe9ea]': menuType === 'table'})} >
            <FiGrid size={22} />
          </button>
        </div>
      </div>
      {
        isApplicationsLoading ?
        <div className='min-h-[50vh] flex items-center justify-center relative z-50 bg-white' >
          <div className='loading' >
            <div className='box' />
          </div>
        </div>
        :
        <div className='mt-6' >
          {getPageContent()}
          <div className='mt-4 flex items-center justify-between' >
            <div className='flex gap-3 items-center' >
              <button onClick={()=> page > 1 && setPage(page-1) } className='border rounded-full p-1 hover:text-azure hover:border-azure' >
                <FiChevronLeft size={16} />
              </button>
              <span className='font-semibold text-sm' >{page}</span>
              <button onClick={()=> !isLastPage && setPage(page+1) } className='border rounded-full p-1 hover:text-azure hover:border-azure' >
                <FiChevronRight size={16} />
              </button>
            </div>
            <div className='flex items-center w-max gap-5' >
              <label className='w-max text-sm' >Élement par page:</label>
              <select id="sizeOfPage" defaultValue={10} onChange={(e)=> setSizeOfPage(e.target.value) } className="appearance-none bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-14 p-2">
                <option value={10} >10</option>
                <option value={15} >15</option>
                <option value={20} >20</option>
              </select>
            </div>
          </div>
        </div>
      }

    </motion.div>
  )
}
