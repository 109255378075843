import React, {useContext} from 'react';
import cx from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import {FiMail, FiMap, FiPhone, FiX} from 'react-icons/fi';

import { UserContext } from '../Contexts/UserContext';
import bromeLogoDark from '../assets/brome-logo-dark.png';
import lookaHrLogo from "../assets/Logo_LookaHR.png";

export default function MobileSideBar({close, isOpen}) {

  const { isLogged } = useContext(UserContext);
  const { t } = useTranslation();
  let { companyId } = useParams();

  const variants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      }
    },
    closed: {
      opacity: 0,
      x: "-100%",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      }
    }
  }

  return (
    <motion.div 
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        className='absolute top-0 left-0 h-[100dvh] w-[100vw] bg-white z-50 flex flex-col justify-between' 
      >
      <div>
        <div className='flex items-center justify-between px-4 py-4 text-black' >
          <ul className='flex items-center gap-6 font-semibold' >
            <li>
              <div className='w-32 h-full mr-10' >
                <Link to={"/"+companyId} >
                  <img src={bromeLogoDark} className='w-full h-full object-contain' />
                </Link>
              </div>
            </li>
          </ul>
          <ul className='flex items-center gap-6 font-semibold' >
            <li className='lg:hidden block' onClick={()=> close() } >
              <FiX size={26} />
            </li>
          </ul>
        </div>
        <div className='px-4 py-4' >
          <ul className='flex flex-col gap-3' >
            <li className='w-max' >
              <Link to={"/"+companyId} onClick={()=> close() } className='group text-2xl cursor-pointer' >
                {t("NAV.HOME")}
                <div className='border-b-2 border-brome w-0 group-hover:w-[120%] transition-all duration-300' />
              </Link>    
            </li>
            <li className='w-max' >
              <Link to={"/"+companyId+"/#jobs"} onClick={()=> close() } className='group text-2xl cursor-pointer' >
                {t("NAV.JOBS")}
                <div className='border-b-2 border-brome w-0 group-hover:w-[120%] transition-all duration-300' />
              </Link>
            </li>
            {
              isLogged &&
              <li className='w-max' >
                <Link to={`/${companyId}/applications`} onClick={()=> close() } className='group text-2xl cursor-pointer' >
                  {t("NAV.APPLICATIONS")}
                  <div className='border-b-2 border-brome w-0 group-hover:w-[120%] transition-all duration-300' />
                </Link>
              </li>
            }
            {
              isLogged &&
              <li className='w-max' >
                <Link to={`/${companyId}/compte`} onClick={()=> close() } className='group text-2xl cursor-pointer' >
                  {t("COMMON.ACCOUNT")}
                  <div className='border-b-2 border-brome w-0 group-hover:w-[120%] transition-all duration-300' />
                </Link>
              </li>
            }
            {
              !isLogged &&
              <li className='w-max' >
                <Link to={`/${companyId}/login`} onClick={()=> close() } className='group text-2xl cursor-pointer' >
                  {t("COMMON.SIGNIN")}
                  <div className='border-b-2 border-brome w-0 group-hover:w-[120%] transition-all duration-300' />
                </Link>
              </li>
            }
            {
              !isLogged &&
              <li className='w-max' >
                <Link to={`/${companyId}/sign-up`} onClick={()=> close() } className='group text-2xl cursor-pointer' >
                  {t("COMMON.SIGNUP")}
                  <div className='border-b-2 border-brome w-0 group-hover:w-[120%] transition-all duration-300' />
                </Link>
              </li>
            }
          </ul>
        </div>
      </div>
      <div className='px-4 py-4'>
        <ul className='flex flex-col gap-3 text-sm mb-6'>
          <li className='font-bold text-gray-400 mb-2'>BROME Consulting & Technologie</li>
          <li className='flex gap-2 items-center text-gray-600'>
            <FiMail className='min-w-[20px]'/>
            <span className='font-semibold'>recrute@brome.ma</span>
          </li>
          <li className='flex gap-2 items-center text-gray-600'>
            <FiPhone className='min-w-[20px]'/>
            <span className='font-semibold'>+212-5225-25652</span>
          </li>
          <li className='flex gap-2 items-center text-gray-600'>
            <FiMap className='min-w-[20px]'/>
            <span className='font-semibold'>1er Etage, N° 15, Naciri, Alpha Business Center, Bd Zoulikha Nasri, Casablanca 20000</span>
          </li>
        </ul>
        <div className="pt-6 border-t" >
          <div className='w-32 h-max mb-2'>
            <img src={lookaHrLogo} className='w-full h-full object-contain'/>
          </div>
          <p className='text-xs text-gray-400'>© 2024 BROME. ALL RIGHTS RESERVED</p>
        </div>
      </div>
    </motion.div>
  )
}
