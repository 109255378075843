import React, { useEffect, useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiEye, FiEyeOff, FiCheckCircle, FiXOctagon } from 'react-icons/fi';
import { UserContext } from '../Contexts/UserContext';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin, BsGithub } from "react-icons/bs";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useLinkedIn } from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';

import { loginEvent } from '../Contexts/events';
import { getLogoFile, getTenantData } from '../services/tenantService';
import { accountCreate, accountLogin } from '../services/accountService';

export default function Login() {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  let { companyId } = useParams();
  const { t } = useTranslation();
  const { tenant, setTenant } = useContext(UserContext);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

  const [loading, setLoading] = useState(false);
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [logoImg, setLogoImg] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [isEmailValide, setIsEmailValide] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const googleLogin = async (providerId, isFromSignUp)=>{
    setLoading(true);

    if (!executeRecaptcha) {
      console.log('reCAPTCHA not yet available');
      return;
    }
    const recaptchaToken = await executeRecaptcha('google_login');

    const config = {
      headers: {
        'XTENANT-HEADER': companyId,
      },
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/gmail`, {providerId, recaptchaResponse: recaptchaToken}, config);
      localStorage.setItem('accessToken', response.data.accessToken);
      if (response.data.refreshToken) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
      }

      loginEvent.emit("login", true); 
      setTimeout(() => {
        setLoading(false);
        navigate("/" + companyId, { state: { isFromSignUp: isFromSignUp } });
      }, 1000);
    } catch (error) {
      error.response && setErrorCode(error.response.data.message);
      console.error('Error signing in:', error);
      setLoading(false);
    }
  }

  const submitGoogleSignUp = async (data)=>{
    setErrorCode("");

    if (!executeRecaptcha) {
      console.log('reCAPTCHA not yet available');
      return;
    }

    const token = await executeRecaptcha('signUp_form');

    const formData = new FormData();
    formData.append('userDto', new Blob([JSON.stringify({
      firstName: data.given_name,
      lastName: data.family_name,
      email: data.email,
      password: null,
      telephone: null,
      enabled: true,
      provider: "GOOGLE",
      providerId: data.sub
    })], { type: 'application/json' }));

    formData.append('recaptchaResponse', token);

    accountCreate(companyId, formData)
    .then(()=> googleLogin(data.sub, true) )
    .catch((error)=>{
      error.response && setErrorCode(error.response.data.message);
      console.error('test There was an error:', error);
    })
    .finally(()=> setLoading(false) )
  }

  const checkProviderId = async (data) => {
    setLoading(true);
    const formData = new FormData();
   
    formData.append('providerId', data.sub);
    formData.append('provider', 'GOOGLE');
   
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'XTENANT-HEADER': companyId,
      },
    };
   
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/checkProviderId`, formData, config);
      if(response.data){
        googleLogin(data.sub, false);
      }else{
        submitGoogleSignUp(data);
      }
    } catch (error) {
      console.error('There was an error:', error);
      setLoading(false);
    }
  };

  const googleSignUp = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
      );
      checkProviderId(userInfo.data);
    },
    onError: errorResponse => console.log(errorResponse),
  });

  const YOUR_REDIRECT_URI = "http://localhost:3000/linkedin";
  const LinkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${YOUR_REDIRECT_URI}&scope=r_liteprofile%20r_emailaddress`;

  const { linkedInLogin } = useLinkedIn({
    clientId: '7754wc862113x3',
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onSubmit = async (data) =>{

    if (!executeRecaptcha) {
      console.log('reCAPTCHA not yet available');
      return;
    }

    const recaptchaToken = await executeRecaptcha('login_form');

    setLoading(true);
    setErrorCode("");

    await accountLogin(companyId, data, recaptchaToken)
    .then((data)=>{
      console.log(data);
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      loginEvent.emit("login", true); 
      navigate("/"+companyId);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch((error)=>{
      error.response && setErrorCode(error.response.data.message);
      console.error('test There was an error:', error);
    })
    .finally(()=> setLoading(false) )
  };

  useEffect(()=>{
    if(companyId){
      getTenantData(companyId).then((data)=>{
        setTenant(data);
        getLogoFile(data.logo.storedFilename).then((img)=> setLogoImg(img) );
      })
    }
  },[companyId]);
  
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='flex flex-col items-center justify-center min-h-[100vh] w-full' 
    >
      <div className='w-max h-12 mb-10' >
        <img src={logoImg} className='w-auto h-full contain' />
      </div>
      <div className='lg:w-1/3 w-[80%] min-h-[30vh] bg-white customShadow p-6 rounded-xl' >
        {
          loading ?
          <div className='h-[30vh] flex items-center justify-center' >
            <div className='loading' >
              <div className='box' />
            </div>
          </div>
          :
          <>
            <h1 className='font-semibold text-3xl mb-10' >{t("AUTH.LOGIN.TITLE")}</h1>
            { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
            <div className='flex flex-col gap-3' >
              <button onClick={()=> googleSignUp() } className='flex gap-3 items-center justify-center border rounded-xl w-full bg-white px-2 py-2 cursor-pointer hover:border-sky-600' >
                <FcGoogle size={24} />
                <span className='text-sm font-semibold' >Continue with Google</span>
              </button>
              <button 
                // onClick={() => window.location.href = LinkedInAuthUrl} 
                className='flex gap-3 items-center justify-center border rounded-xl w-full bg-gray-200 px-2 py-2 cursor-default' >
                <BsLinkedin size={24} color='#0065B4' />
                <span className='text-sm font-semibold' >Continue with LinkedIn</span>
              </button>
              <a 
                // href={`https://github.com/login/oauth/authorize?scope=user&client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GITHUB_REDIRECT_URI}`}
                className='flex gap-3 items-center justify-center border rounded-xl w-full bg-gray-200 px-2 py-2' >
                <BsGithub size={24} color='#1F2328' />
                <span className='text-sm font-semibold' >{t("COMMON.CONTINUE_WITH")} GitHub</span>
              </a>
            </div>
            <div className='my-4 flex items-center gap-3 justify-center font-semibold text-gray-500' >
              <div className='w-1/3 bg-gray-400 h-[1px]' /> {t("COMMON.OR")} <div className='w-1/3 bg-gray-400 h-[1px]' />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
              <div className='relative' >
                <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                  <label className='text-xs text-gray-500 font-semibold' >{t("FORM.EMAIL_INPUT.LABEL")} *</label>
                  { errors.email && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
                </div>
                <input 
                  className='border rounded-xl px-3 py-2.5 text-sm w-full mb-4 hover:border-brome pr-8' 
                  placeholder={t("FORM.EMAIL_INPUT.PLACEHOLDER")}
                  {...register("email", { required: true, pattern: /^\S+@\S+$/i })} 
                  onChange={(e)=> validateEmail(e.target.value) }
                />
                {
                  isEmailValide 
                  ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[2rem]' />
                  : <FiXOctagon size={16} className='text-red-700 absolute right-4 top-[2rem]' />
                }
              </div>

              <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                <label className='text-xs text-gray-500 font-semibold' >{t("FORM.PASSWORD_INPUT.LABEL")} *</label>
                { errors.password && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
              </div>
              <div className='relative mb-4' >
                <input 
                  type={isPassVisible ? "text" : "password"}
                  className='border rounded-xl px-3 pr-10 py-2.5 text-sm w-full hover:border-brome' 
                  placeholder={t("FORM.PASSWORD_INPUT.PLACEHOLDER")}
                  {...register("password", { required: true })} 
                />
                {
                  isPassVisible
                  ? <FiEyeOff size={20} onClick={()=> setIsPassVisible(false) } className='absolute right-2 top-1/4 cursor-pointer' />
                  : <FiEye size={20} onClick={()=> setIsPassVisible(true) } className='absolute right-2 top-1/4 cursor-pointer' />
                }
              </div>

              <input 
                type="submit" 
                value={t("COMMON.SIGNIN")}
                className='text-sm font-semibold bg-brome px-5 py-2.5 rounded-lg text-white mt-6 cursor-pointer hover:shadow-lg hover:bg-brome-hover' 
              />
            </form>
            <p className='text-xs text-gray-400 font-semibold mt-3' >
              {t("AUTH.LOGIN.MSG.PART_1")}
              <Link to={`/${companyId}/sign-up`} className='ml-2 text-sky-600 cursor-pointer hover:underline' >{t("AUTH.LOGIN.MSG.PART_2")}</Link>
            </p>
          </>
        }
      </div>
      <Link to={`/${companyId}`} className='text-sm text-gray-400 mt-3 cursor-pointer hover:text-brome' >{t("AUTH.SEE_JOBS")}</Link>
    </motion.div>
  )
}
