import axios from "axios";

export const getAnnoncesPage = async (companyId, page, formData)=>{

  const params = {
    page: page,
    size: '6',
    sort: `id,asc`,
  };

  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
    params,
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/annonces/page`, formData, config);
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
}

export const getAnnoncesSearch = async (companyId, formData)=>{

  const params = {
    page: '1',
    size: '6',
    sort: `id,asc`,
  };

  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
    params,
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/annonces/page`, formData, config);
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
}

export const getAnnoncesFilters = async (companyId) => {
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/data/filters_data`,config);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the tenants:', error);
    throw error;
  }
};

export const getAnnoncesUrgents = async (companyId) => {
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/annonces/urgent`, config)
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
}

export const getAnnonceShow = async (companyId, id)=>{
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },

  };
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/annonces/show/${id}`,config);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the tenants:', error);
    throw error;
  }
}


export const getAllAnnonces = async (companyId, formData)=>{

  const params = {
    page: '1',
    size: '6',
    sort: `id,asc`,
  };

  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
    params,
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/annonces/all`, formData, config);
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
}