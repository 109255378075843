import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function OauthRedirect() {

  const {t} = useTranslation();

  // const getUserToken = async (code)=>{
  //   const githubResponse = await axios.post('https://github.com/login/oauth/access_token', {
  //     client_id: process.env.REACT_APP_GITHUB_CLIENT_ID,
  //     client_secret: process.env.REACT_APP_GITHUB_CLIENT_SECRET,
  //     code,
  //   }, {
  //     headers: {
  //       'Accept': 'application/json'
  //     }
  //   });
    
  //   const accessToken = githubResponse.data.access_token;
  //   return accessToken;
  // }

  // const getUserInfo = async (code) => {
  //   const accessToken = await getUserToken(code);
  //   try {
  //     const response = await axios.get('https://api.github.com/user', {
  //       headers: { Authorization: `Bearer ${accessToken}` }
  //     });
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error('Error fetching GitHub user information:', error);
  //   }
  // };

  // useEffect(() => {
  //   const url = window.location.href;
  //   const hasCode = url.includes("?code=");
  //   console.log(hasCode)
  //   if (hasCode) {
  //     const newUrl = url.split("?code=");

  //     getUserInfo(newUrl[1]);
      
  //   }
  // }, []);


  const handleLogin = async (code) => {
    try {
      // Exchange the code for an access token
      const data = await fetch('https://github.com/login/oauth/access_token', {
        method: "POST",
        body: {
          client_id: process.env.REACT_APP_GITHUB_CLIENT_ID,
          client_secret: process.env.REACT_APP_GITHUB_CLIENT_SECRET,
          code,
        },
        headers: {
          "Content-Type": "application/json"
        }
      }).then((response) => response.json());

      const accessToken = data.access_token;

      // Fetch the user's GitHub profile
      const userProfile = await fetch('https://api.github.com/user', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'User-Agent': 'Your-App-Name'
        }
      });

      console.log(`Welcome, ${userProfile.data.name}!`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGitHubCallback = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    
    if (code) {
      console.log(code);
      handleLogin(code);
    }
  };  

  useEffect(()=>{
    handleGitHubCallback();
  },[])

  return (
    <div>
      {t("COMMON.LOADING")} ....
    </div>
  )
}
