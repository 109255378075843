import React,{ createContext, useState, useEffect } from "react";
import axios from "axios";
import auth_API from "../services/api";

import { loginEvent } from "./events";
import { getUser } from "../services/userService";
import { getAllCandidatures } from "../services/candidatureService";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const [tenant, setTenant] = useState([]);
  const [isLogged, setIsLogged] = useState(false);
  const [applicatedJobs, setApplicatedJobs] = useState([]);
  const [user, setUser] = useState({});

  const getUserData = async ()=>{
    getUser(tenant.uuid)
    .then((data)=> setUser(data) )
    .catch((error)=>{
      console.error('There was an error fetching user data:', error);
    })
  }

  const getApplicatedJobs = async ()=>{
    getAllCandidatures(tenant.uuid).then((data)=> setApplicatedJobs(data.map((it)=> it.annonce.id)) )
  }

  const isUserLogged = ()=>{
    if (localStorage.getItem('accessToken')) {
      setIsLogged(true);
    } else if (localStorage.getItem('refreshToken')) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  }

  function hexToRGBA(hex, opacity = 1) {
    let r = 0, g = 0, b = 0;
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  useEffect(()=>{
    if(Object.keys(tenant).length > 0){
      localStorage.setItem('tenantId', tenant.uuid);
      document.documentElement.style.setProperty('--dynamic-color', tenant.color);
      document.documentElement.style.setProperty('--dynamic-color-hover', hexToRGBA(tenant.color, 0.7));
      if(isLogged){
        getUserData();
        getApplicatedJobs();
      }
    }
  },[tenant, isLogged]);

  useEffect(() => {
    isUserLogged();
  }, []);
  
  useEffect(() => {

    const handleLoginEvent = (state) => {
      setIsLogged(state);
      setApplicatedJobs([]);
    };

    loginEvent.on('login', handleLoginEvent);

    return () => {
      loginEvent.removeListener('login', handleLoginEvent);
    };
  }, [loginEvent]);

  const values = {
    isLogged,
    tenant,
    setTenant,
    applicatedJobs,
    getApplicatedJobs,
    user
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};