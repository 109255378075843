import React, { useEffect, useState, useRef, useContext } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { FiCheckCircle, FiUploadCloud, FiXOctagon } from 'react-icons/fi'; 
import { Link, useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { UserContext } from '../Contexts/UserContext';
import axios from 'axios';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin, BsGithub } from "react-icons/bs";
import { loginEvent } from '../Contexts/events';
import { getLogoFile, getTenantData } from '../services/tenantService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import logo from '../assets/brome-logo-dark.png';

import countryCodes from '../utils/countryCodes.json';
import { accountCreate, accountLogin } from '../services/accountService';

export default function SignUp() {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const countryCodesInputRef = useRef(null);
  const countryCodesMenuRef = useRef(null);
  const navigate = useNavigate();
  const cvFileRef = useRef(null);
  let { companyId } = useParams();
  const { tenant, setTenant } = useContext(UserContext);
  const { t } = useTranslation();
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const uploadedFile = watch('cvFile');
  const [errorCode, setErrorCode] = useState("");

  const [loading, setLoading] = useState(false);
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [isCountryCodeSelectOpen, setIsCountryCodeSelectOpen] = useState(false);
  const [isEmailValide, setIsEmailValide] = useState(false);

  const [isPassVisible, setIsPassVisible] = useState(false);
  const [logoImg, setLogoImg] = useState("");

  const onSubmit = async (data, provider) =>{
    // checkProviderId();
    // if(!isValidPhoneNumber(data.countryCode+data.telephone)){
    //   return setErrorCode("PHONE_INVALID");
    // }
    if (!executeRecaptcha) {
      console.log('reCAPTCHA not yet available');
      return;
    }

    const token = await executeRecaptcha('signUp_form');

    setErrorCode("");
    setLoading(true);
    const formData = new FormData();
    formData.append('userDto', new Blob([JSON.stringify({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      telephone: data.telephone,
      enabled: true,
      provider: provider,
      providerId: data.providerId ? data.providerId : null
    })], { type: 'application/json' }));

    if (data.cvFile[0]) {
      formData.append('pj', data.cvFile[0]);
    }
    formData.append('recaptchaResponse', token);

    await accountCreate(companyId, formData)
    .then(()=> passwordLogin(data) )
    .catch((error)=>{
      error.response && setErrorCode(error.response.data.message);
      console.error('test There was an error:', error);
    })
    .finally(()=> setLoading(false) )
  };

  const passwordLogin = async (data) =>{
    if (!executeRecaptcha) {
      console.log('reCAPTCHA not yet available');
      return;
    }

    const recaptchaToken = await executeRecaptcha('login_form');

    setLoading(true);

    await accountLogin(companyId, data, recaptchaToken)
    .then((response)=>{
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      navigate("/"+companyId);
      loginEvent.emit("login", true); 
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    })
    .catch((error)=>{
      error.response && setErrorCode(error.response.data.message);
      console.error('test There was an error:', error);
    })
    .finally(()=> setLoading(false) )
  };

  const googleLogin = async (providerId, isFromSignUp)=>{
    setLoading(true);

    if (!executeRecaptcha) {
      console.log('reCAPTCHA not yet available');
      return;
    }
    const recaptchaToken = await executeRecaptcha('google_login');

    const config = {
      headers: {
        'XTENANT-HEADER': companyId,
      },
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/gmail`, {providerId, recaptchaResponse: recaptchaToken}, config);
      console.log(response.data);
      localStorage.setItem('accessToken', response.data.accessToken);
      if (response.data.refreshToken) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
      }

      loginEvent.emit("login", true); 
      setTimeout(() => {
        navigate("/" + companyId, { state: { isFromSignUp: isFromSignUp } });
        setLoading(false);
      }, 1000);
    } catch (error) {
      error.response && setErrorCode(error.response.data.message);
      console.error('Error signing in:', error);
      setLoading(false);
    }
  }

  const submitGoogleSignUp = async (data)=>{
    setErrorCode("");

    if (!executeRecaptcha) {
      console.log('reCAPTCHA not yet available');
      return;
    }

    const token = await executeRecaptcha('signUp_form');

    const formData = new FormData();
    formData.append('userDto', new Blob([JSON.stringify({
      firstName: data.given_name,
      lastName: data.family_name,
      email: data.email,
      password: null,
      telephone: null,
      enabled: true,
      provider: "GOOGLE",
      providerId: data.sub
    })], { type: 'application/json' }));

    formData.append('recaptchaResponse', token);

    await accountCreate(companyId, formData)
    .then(()=> googleLogin(data.sub, true) )
    .catch((error)=>{
      error.response && setErrorCode(error.response.data.message);
      console.error('test There was an error:', error);
    })
    .finally(()=> setLoading(false) )
  }

  const checkProviderId = async (data) => {
    setLoading(true);
    const formData = new FormData();
   
    formData.append('providerId', data.sub);
    formData.append('provider', 'GOOGLE');
   
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'XTENANT-HEADER': companyId,
      },
    };
   
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/checkProviderId`, formData, config);
      if(response.data){
        googleLogin(data.sub, false);
      }else{
        submitGoogleSignUp(data);
      }
    } catch (error) {
      console.error('There was an error:', error);
      setLoading(false);
    }
  };

  const googleSignUp = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
      );
      checkProviderId(userInfo.data);
    },
    onError: errorResponse => console.log(errorResponse),
  });

  const YOUR_REDIRECT_URI = "http://localhost:3000/linkedin";
  const LinkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${YOUR_REDIRECT_URI}&scope=r_liteprofile%20r_emailaddress`;

  const handleCountryCodeInputBlur = (e) => {
    setTimeout(() => {
      if (countryCodesMenuRef.current && !countryCodesMenuRef.current.contains(document.activeElement)) {
        setIsCountryCodeSelectOpen(false);
      }
    }, 0);
  };  

  const handleCountryCodeChange = (value) =>{
    setIsCountryCodeSelectOpen(value.length > 0);

    const cleanedInputValue = value.startsWith('+') ? value.slice(1) : value;
    const filterBy = cleanedInputValue.match(/^\d/) ? 'code' : 'country';

    const filtered = countryCodes.filter(option => {
      const searchValue = cleanedInputValue.toLowerCase();
      return filterBy === 'code' ?
        option.code.startsWith(searchValue) :
        option.country.toLowerCase().includes(searchValue);
    });
    setFilteredCodes(filtered);
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      // setValue('cvFile', files);
      setValue('cvFile', files, { shouldValidate: true });
    }
  };

  const openFileDialog = () => {
    cvFileRef.current.click();
  };

  useEffect(()=>{
    setFilteredCodes(countryCodes);
  },[]);

  useEffect(()=>{
    if(companyId){
      getTenantData(companyId).then((data)=>{
        setTenant(data);
        getLogoFile(data.logo.storedFilename).then((img)=> setLogoImg(img) );
      })
    }
  },[companyId]);

  const handleClickOutside = (event) => {
    if (countryCodesMenuRef.current && !countryCodesMenuRef.current.contains(event.target)) {
      setIsCountryCodeSelectOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectCountryCode = (code)=>{
    setValue('countryCode', `+${code}` );
    setIsCountryCodeSelectOpen(false);
  }
  
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='flex flex-col items-center justify-center min-h-[90vh] w-full py-20' 
    >
      <div className='w-max h-12 mb-10' >
        <img src={logoImg} className='w-auto h-full contain' />
      </div>
      <div className='lg:w-1/3 w-[90vw] min-h-[30vh] bg-white customShadow p-6 rounded-xl' >
        {
          loading ?
          <div className='h-[30vh] flex items-center justify-center' >
            <div className='loading' >
              <div className='box' />
            </div>
          </div>
          :
          <>
            <h1 className='font-semibold text-2xl mb-8' >{t("AUTH.SIGNUP.TITLE")}</h1>
            { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
            <div className='flex flex-col gap-3' >
              <button onClick={()=> googleSignUp() } className='flex gap-3 items-center justify-center border rounded-xl w-full bg-white px-2 py-2 cursor-pointer hover:border-sky-600' >
                <FcGoogle size={24} />
                <span className='text-sm font-semibold' >{t("COMMON.CONTINUE_WITH")} Google</span>
              </button>
              <button 
                // onClick={() => window.location.href = LinkedInAuthUrl} 
                className='flex gap-3 items-center justify-center border rounded-xl w-full bg-gray-200 px-2 py-2 cursor-default' >
                <BsLinkedin size={24} color='#0065B4' />
                <span className='text-sm font-semibold' >Continue with LinkedIn</span>
              </button>
              <a 
                // href={`https://github.com/login/oauth/authorize?scope=user&client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GITHUB_REDIRECT_URI}`}
                className='flex gap-3 items-center justify-center border rounded-xl w-full bg-gray-200 px-2 py-2' >
                <BsGithub size={24} color='#1F2328' />
                <span className='text-sm font-semibold' >{t("COMMON.CONTINUE_WITH")} GitHub</span>
              </a>
            </div>
            <div className='my-4 flex items-center gap-3 justify-center font-semibold text-gray-500' >
              <div className='w-1/3 bg-gray-400 h-[1px]' /> {t("COMMON.OR")} <div className='w-1/3 bg-gray-400 h-[1px]' />
            </div>
            <form onSubmit={handleSubmit( (data)=> onSubmit(data, "PASSWORD") )} className='flex flex-col w-full' >
              <div className='grid grid-cols-1 lg:grid-cols-2 mb-4 gap-4' >
                <div>
                  <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                    <label className='text-xs text-gray-500 font-semibold min-w-[50%]' >{t("FORM.LAST_NAME_INPUT.LABEL")} *</label>
                    { errors.lastName && <label className='text-xs text-red-700 font-semibold truncate' >{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input 
                    className='border rounded-xl px-3 py-2.5 text-sm w-full hover:border-brome' 
                    placeholder={t("FORM.LAST_NAME_INPUT.PLACEHOLDER")}
                    {...register("lastName", { required: true })} 
                  />
                </div>
                <div>
                  <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                    <label className='text-xs text-gray-500 font-semibold min-w-[50%]' >{t("FORM.FIRST_NAME_INPUT.LABEL")} *</label>
                    { errors.firstName && <label className='text-xs text-red-700 font-semibold truncate' >{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input 
                    className='border rounded-xl px-3 py-2.5 text-sm w-full hover:border-brome' 
                    placeholder={t("FORM.FIRST_NAME_INPUT.PLACEHOLDER")}
                    {...register("firstName", { required: true })} 
                  />
                </div>
              </div>
              <div className='relative' >
                <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                  <label className='text-xs text-gray-500 font-semibold' >{t("FORM.EMAIL_INPUT.LABEL")} *</label>
                  { errors.email && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
                </div>
                <input 
                  className='border rounded-xl px-3 py-2.5 text-sm w-full mb-4 hover:border-brome pr-8' 
                  placeholder={t("FORM.EMAIL_INPUT.PLACEHOLDER")}
                  {...register("email", { required: true, pattern: /^\S+@\S+$/i })} 
                  onChange={(e)=> validateEmail(e.target.value) }
                />
                {
                  isEmailValide 
                  ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[2rem]' />
                  : <FiXOctagon size={16} className='text-red-700 absolute right-4 top-[2rem]' />
                }
              </div>
              <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                <label className='text-xs text-gray-500 font-semibold' >{t("FORM.PASSWORD_INPUT.LABEL")} *</label>
                { errors.password && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
              </div>
              <div className='relative mb-1' >
                <input 
                  type={isPassVisible ? "text" : "password"}
                  className='border rounded-xl px-3 pr-10 py-2.5 text-sm w-full hover:border-brome' 
                  placeholder={t("FORM.PASSWORD_INPUT.PLACEHOLDER")}
                  // {...register("password", { required: false })} 
                  {...register("password", { 
                    required: "required", 
                    minLength: {
                      value: 8,
                      message: "Le mot de passe doit contenir au moins 8 caractères"
                    },
                    maxLength: {
                      value: 16,
                      message: "Le mot de passe ne doit pas dépasser 16 caractères"
                    },
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                      message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
                    }
                  })} 
                />
                {
                  isPassVisible
                  ? <FiEyeOff size={20} onClick={()=> setIsPassVisible(false) } className='absolute right-2 top-1/4 cursor-pointer' />
                  : <FiEye size={20} onClick={()=> setIsPassVisible(true) } className='absolute right-2 top-1/4 cursor-pointer' />
                }
              </div>
              <div className='mb-4 px-3.5' >
                <p className='text-xs text-gray-500' >{errors.password ? errors.password.message !== "required" && errors.password.message : null }</p>
              </div>

              <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                <label className='text-xs text-gray-500 font-semibold' >{t("FORM.PHONE_INPUT.LABEL")} *</label>
                { (errors.telephone) && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
              </div>
              <div className='flex gap-2 relative' style={{zIndex: 10}}>
                {/* <input 
                  ref={countryCodesInputRef}
                  className='border rounded-xl w-[7rem] px-3 py-2.5 text-sm mb-4 hover:border-brome' 
                  placeholder="Code pays"
                  {...register("countryCode", { required: true })}
                  onChange={(e)=> handleCountryCodeChange(e.target.value) }
                  onFocus={()=> setIsCountryCodeSelectOpen(true) }
                  // onBlur={handleCountryCodeInputBlur}
                />
                {
                  isCountryCodeSelectOpen &&
                  <div ref={countryCodesMenuRef} style={{zIndex: 12}} className='absolute border rounded-xl w-[7rem] max-h-52 top-11 left-0 overflow-y-scroll bg-white' >
                    <ul className='flex flex-col' onClick={()=> console.log("test") } >
                      {filteredCodes.map((country)=>(
                        <li key={country.iso} onClick={()=> selectCountryCode(country.code) } className='text-xs font-semibold border-b p-2 hover:bg-gray-100 cursor-pointer' >
                          + {country.code} ({country.iso})
                        </li>
                      ))}
                    </ul>
                  </div>
                } */}
                <input 
                  className='border rounded-xl w-full px-3 py-2.5 text-sm mb-4 hover:border-brome' 
                  placeholder={t("FORM.PHONE_INPUT.PLACEHOLDER")}
                  {...register("telephone", { required: true })} 
                />
              </div>

              <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                <label className='text-xs text-gray-500 font-semibold' >{t("FORM.CV_INPUT.LABEL")} *</label>
                { errors.cvFile && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
              </div>
              <div 
                onClick={openFileDialog} 
                onDragOver={onDragOver}
                onDrop={onDrop}
                className='h-28 px-1 border border-dashed rounded-xl flex flex-col mb-4 items-center justify-center cursor-pointer hover:border-brome' 
              >
                {(uploadedFile && uploadedFile.length > 0) 
                  ? <p>{uploadedFile[0].name}</p>
                  : 
                  <>
                    <FiUploadCloud size={28} className='text-gray-500' />
                    <p className='text-sm text-gray-500 text-center' >{t("FORM.CV_INPUT.PLACEHOLDER")}</p>
                  </>
                }
              </div>

              <input
                {...register('cvFile', { required: true })}
                type="file"
                style={{ display: 'none' }}
                id="fileUpload"
                accept=".pdf,.doc,.docx"
                onChange={(e) => {
                  const files = e.target.files;
                  setValue('cvFile', files.length > 0 ? files : null, { shouldValidate: true });
                }}
                ref={cvFileRef}
              />

              <input 
                type="submit" 
                value={t("COMMON.SIGNUP")}
                className='text-sm font-semibold bg-brome px-5 py-2.5 rounded-lg text-white mt-6 cursor-pointer hover:shadow-lg hover:bg-brome-hover' 
              />
            </form>
            <p className='text-xs text-gray-400 font-semibold mt-3' >
              {t("AUTH.SIGNUP.MSG")}
              <Link to={`/${companyId}/login`} className='ml-2 text-sky-600 cursor-pointer hover:underline' >{t("COMMON.SIGNIN")}</Link>
            </p>
          </>
        }
      </div>
      <Link to={`/${companyId}`} className='text-sm text-gray-400 mt-3 cursor-pointer hover:text-brome' >{t("AUTH.SEE_JOBS")}</Link>
    </motion.div>
  )
}
