import React, {useState, useEffect, useRef, useContext} from 'react';
import { useForm } from "react-hook-form";
import { motion } from 'framer-motion';
import { FiBriefcase, FiEdit, FiGrid, FiLogOut, FiMenu, FiTable, FiUser, FiUserCheck, FiFileText, FiDownload } from 'react-icons/fi';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import auth_API from '../services/api';
import moment from 'moment';
import 'moment/locale/fr';
import { saveAs } from 'file-saver';

import countryCodes from '../utils/countryCodes.json';
import ErrorModal from './ErrorModal';
import { ErrorContext } from '../Contexts/ErrorContext';
import { loginEvent } from '../Contexts/events';
import { getUser, getUserCv } from '../services/userService';
import { accountUpdate, getCvFile } from '../services/accountService';

export default function Account() {

  const {t} = useTranslation();
  let { companyId } = useParams();
  const navigate = useNavigate();
  const cvFileRef = useRef(null);
  const countryCodesInputRef = useRef(null);
  const countryCodesMenuRef = useRef(null);

  const {isErrorModalOpen, openErrorModal, closeErrorModal, errorCode, setErrorCode} = useContext(ErrorContext);

  const [currTab, setCurrTab] = useState(0);
  const [user, setUser] = useState({});
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [cvFile, setCvFile] = useState(null);
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [isCountryCodeSelectOpen, setIsCountryCodeSelectOpen] = useState(false);

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

  const handleCountryCodeInputBlur = (e) => {
    setTimeout(() => {
      if (countryCodesMenuRef.current && !countryCodesMenuRef.current.contains(document.activeElement)) {
        setIsCountryCodeSelectOpen(false);
      }
    }, 0);
  };  

  const handleCountryCodeChange = (value) =>{
    setIsCountryCodeSelectOpen(value.length > 0);

    const cleanedInputValue = value.startsWith('+') ? value.slice(1) : value;
    const filterBy = cleanedInputValue.match(/^\d/) ? 'code' : 'country';

    const filtered = countryCodes.filter(option => {
      const searchValue = cleanedInputValue.toLowerCase();
      return filterBy === 'code' ?
        option.code.startsWith(searchValue) :
        option.country.toLowerCase().includes(searchValue);
    });
    setFilteredCodes(filtered);
  }

  const selectCountryCode = (code)=>{
    setValue('countryCode', `+${code}` );
    setIsCountryCodeSelectOpen(false);
  }

  const signOut = ()=>{
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    loginEvent.emit("login", false); 
    navigate(`/${companyId}`, { replace: true });
  }

  const downloadCvFile = async ()=>{
    await getUserCv(companyId).then((cvBlob)=>{
      saveAs(cvBlob, user.profil.cv.originalFilename);
    })
  }

  const getUserData = async ()=>{
    
    setIsFetchLoading(true);

    getUser(companyId)
    .then((data)=>{ 
      setUser(data);
      // data.profil.cv && setCvFile( getCvFile(data.profil.cv.storedFilename, companyId) )
    })
    .catch((error)=>{
      error.response.data ? setErrorCode(error.response.data.message) : setErrorCode("ERROR");
      console.error('There was an error fetching user data:', error);
    })
    .finally(()=>
      setTimeout(() => {
        setIsFetchLoading(false);
      }, 1000)
    )
  }

  const updateCv = async (event)=>{
    const file = event.target.files[0];
    setIsUpdateLoading(true);
    const formData = new FormData();
    formData.append('userDto', new Blob([JSON.stringify({
      email: user.email, 
      firstName: user.firstName,
      lastName: user.lastName,
      telephone: user.telephone,
    })], { type: 'application/json' }));

    if (file) {
      formData.append('pj', file);
    }

    accountUpdate(companyId, formData)
    .then((res)=>{ getUserData(); console.log(res) })
    .catch((error)=>{
      setErrorCode(error.response.data.message);
      console.error('There was an error:', error);
    })
    .finally(()=> setIsUpdateLoading(false) )
  }

  const updateUser = async (data)=>{
    setIsUpdateLoading(true);

    const formData = new FormData();
    formData.append('userDto', new Blob([JSON.stringify({
      firstName: data.firstName,
      lastName: data.lastName,
      telephone: data.telephone,
    })], { type: 'application/json' }));

    accountUpdate(companyId, formData)
    .then((res)=>{ getUserData() })
    .catch((error)=>{
      setErrorCode(error.response.data.message);
      console.error('There was an error:', error);
    })
    .finally(()=> setIsUpdateLoading(false) )
  }

  useEffect(()=>{
    getUserData();
  },[]);

  useEffect(()=>{
    setFilteredCodes(countryCodes);
  },[]);

  useEffect(()=>{
    if(Object.keys(user).length > 0){
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('email', user.email);
      setValue('telephone', user.telephone);
    }
  },[user]);

  useEffect(()=>{
    if(errorCode.length > 0){
      openErrorModal();
    }
  },[errorCode]);



  const handleClickOutside = (event) => {
    if (countryCodesMenuRef.current && !countryCodesMenuRef.current.contains(event.target)) {
      setIsCountryCodeSelectOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if(isFetchLoading){
    return(
      <div className='min-h-[80vh] w-full flex items-center justify-center relative z-50 bg-white' >
        <div className='loading' >
          <div className='box' />
        </div>
      </div>
    )
  }

  return(
    <div className='mainSec mt-10 px-4 lg:px-0 flex flex-col lg:flex-row gap-8 min-h-[70vh]'>
      <div className='w-1/4 relative hidden lg:block'>
        <h1 className='font-semibold bg-white absolute top-0 left-3 px-2 translate-y-[-50%]'>{t("COMMON.ACCOUNT")}</h1>
        <div className='border rounded-xl p-3 pt-5'>
          <ul className='flex flex-col gap-2'>
            <li onClick={() => setCurrTab(0)}
                className={cx('flex items-center gap-2 rounded-lg px-3 py-1.5 hover:bg-gray-100 cursor-pointer', {'bg-brome text-white hover:text-black': currTab === 0})}>
              <FiUser/>
              <span className='text-sm font-semibold'>{t("ACCOUNT.NAV.PROFILE")}</span>
            </li>
            <li onClick={() => setCurrTab(1)}
                className={cx('flex items-center gap-2 rounded-lg px-3 py-1.5 hover:bg-gray-100 cursor-pointer', {'bg-brome text-white hover:text-black': currTab === 1})}>
              <FiBriefcase/>
              <span className='text-sm font-semibold'>{t("ACCOUNT.NAV.CV")}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className='w-full relative lg:hidden block'>
        <h1 className='font-semibold bg-white absolute top-0 left-3 px-2 translate-y-[-50%]'>{t("COMMON.ACCOUNT")}</h1>
        <div className='border rounded-xl p-2 pt-4'>
          <ul className='grid grid-cols-2 gap-2'>
            <li  onClick={() => setCurrTab(0)}
                className={cx('flex items-center justify-center gap-2 rounded-lg px-3 py-1.5 hover:bg-gray-100 cursor-pointer', {'bg-brome text-white hover:text-black': currTab === 0})}>
              <FiUser/>
              <span className='text-sm font-semibold'>{t("ACCOUNT.NAV.PROFILE")}</span>
            </li>
            <li onClick={() => setCurrTab(1)}
                className={cx('flex items-center justify-center gap-2 rounded-lg px-3 py-1.5 hover:bg-gray-100 cursor-pointer', {'bg-brome text-white hover:text-black': currTab === 1})}>
              <FiBriefcase/>
              <span className='text-sm font-semibold'>{t("ACCOUNT.NAV.CV")}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className='lg:w-3/4 w-full'>
        <div className='flex items-center justify-between gap-3 border-b pb-2'>
          <div className='flex items-center gap-3'>
            <div className='h-12 w-12 border rounded-full flex items-center justify-center'>
              <FiUser size={28} className='text-gray-500'/>
            </div>
            <p className='font-bold group-hover:text-brome'>{user.firstName} {user.lastName}</p>
          </div>
          <div onClick={() => signOut()}
               className='flex items-center gap-2 border p-1 rounded-xl cursor-pointer text-gray-500 hover:border-[#D6323A] hover:text-[#D6323A]'>
            <FiLogOut/>
            <span className='text-xs font-semibold'>{t("COMMON.SIGNOUT")}</span>
          </div>
        </div>
        {
          isUpdateLoading ?
            <div>
              <div className='loader'/>
            </div>
            :
            currTab === 0 ?
              <form onSubmit={handleSubmit(updateUser)} className='flex flex-col w-full mt-6'>
                <div className='lg:w-1/2 w-full'>
                  <div className='grid grid-cols-1 lg:grid-cols-2 mb-4 gap-4'>
                    <div>
                      <div className='px-3.5 mb-0.5 flex items-center justify-between'>
                        <label
                          className='text-xs text-gray-500 font-semibold min-w-[50%]'>{t("FORM.LAST_NAME_INPUT.LABEL")} *</label>
                        {errors.lastName && <label
                          className='text-xs text-red-700 font-semibold truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                      </div>
                      <input
                        className='border rounded-xl px-3 py-2.5 text-sm w-full hover:border-brome'
                        placeholder={t("FORM.LAST_NAME_INPUT.PLACEHOLDER")}
                        {...register("lastName", {required: true})}
                      />
                    </div>
                    <div>
                      <div className='px-3.5 mb-0.5 flex items-center justify-between'>
                        <label
                          className='text-xs text-gray-500 font-semibold min-w-[50%]'>{t("FORM.FIRST_NAME_INPUT.LABEL")} *</label>
                        {errors.firstName && <label
                          className='text-xs text-red-700 font-semibold truncate'>{t("COMMON.REQUIRED_ERROR")}</label>}
                      </div>
                      <input
                        className='border rounded-xl px-3 py-2.5 text-sm w-full hover:border-brome'
                        placeholder={t("FORM.FIRST_NAME_INPUT.PLACEHOLDER")}
                        {...register("firstName", {required: true})}
                      />
                    </div>
                  </div>

                  <div className='px-3.5 mb-0.5 flex items-center justify-between'>
                    <label className='text-xs text-gray-500 font-semibold'>{t("FORM.EMAIL_INPUT.LABEL")}</label>
                    {errors.email &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='border rounded-lg px-3 py-2 text-sm w-full mb-4 hover:border-brome bg-gray-100'
                    placeholder={user ? user.email : t("FORM.EMAIL_INPUT.PLACEHOLDER")}
                    disabled
                    // {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                  />

                  {/* <div className='px-3.5 mb-0.5 flex items-center justify-between' >
                  <label className='text-xs text-gray-500 font-semibold' >{t("FORM.PASSWORD_INPUT.LABEL")} *</label>
                  { errors.password && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
                </div>
                <div className='relative mb-4' >
                  <input 
                    type={isPassVisible ? "text" : "password"}
                    className='border rounded-xl px-3 pr-10 py-2.5 text-sm w-full hover:border-brome' 
                    placeholder={t("FORM.PASSWORD_INPUT.PLACEHOLDER")}
                    {...register("password", { required: true })} 
                  />
                  {
                    isPassVisible
                    ? <FiEyeOff size={20} onClick={()=> setIsPassVisible(false) } className='absolute right-2 top-1/4 cursor-pointer' />
                    : <FiEye size={20} onClick={()=> setIsPassVisible(true) } className='absolute right-2 top-1/4 cursor-pointer' />
                  }
                </div> */}

                  <div className='px-3.5 mb-0.5 flex items-center justify-between'>
                    <label className='text-xs text-gray-500 font-semibold'>{t("FORM.PHONE_INPUT.LABEL")}</label>
                    {errors.telephone &&
                      <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
                  </div>
                  <input
                    className='border rounded-lg px-3 py-2 text-sm w-full mb-4 hover:border-brome'
                    placeholder={t("FORM.PHONE_INPUT.PLACEHOLDER")}
                    {...register("telephone", {required: true})}
                  />

                  {/* <div className='flex gap-2 relative'>
                  <input 
                    ref={countryCodesInputRef}
                    className='border rounded-xl w-[7rem] px-3 py-2.5 text-sm mb-4 hover:border-brome' 
                    placeholder="Code pays"
                    {...register("countryCode", { required: true })}
                    onChange={(e)=> handleCountryCodeChange(e.target.value) }
                    onFocus={()=> setIsCountryCodeSelectOpen(true) }
                  />
                  {
                    isCountryCodeSelectOpen &&
                    <div ref={countryCodesMenuRef} className='absolute border rounded-xl w-[7rem] max-h-52 top-11 left-0 overflow-y-scroll bg-white' >
                      <ul className='flex flex-col' >
                        {filteredCodes.map((country)=>(
                          <li key={country.iso} onClick={()=>{selectCountryCode(country.code)} } className='text-xs font-semibold border-b p-2 hover:bg-gray-100 cursor-pointer' >
                            + {country.code} ({country.iso})
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                  <input 
                    className='border rounded-xl w-[calc(100%_-_7rem)] px-3 py-2.5 text-sm mb-4 hover:border-brome' 
                    placeholder={t("FORM.PHONE_INPUT.PLACEHOLDER")}
                    {...register("telephone", { required: true })} 
                  />
                </div> */}

                </div>
                <div className='flex justify-end'>
                  <input
                    disabled={isUpdateLoading}
                    type="submit"
                    value={t("COMMON.SAVE")}
                    className='text-sm font-semibold bg-brome px-5 min-w-36 py-2.5 rounded-lg text-white mt-6 cursor-pointer hover:shadow-lg hover:bg-brome-hover'
                  />
                </div>
              </form>
              :
              <div className='flex flex-col w-full mt-6'>
                <div className='mb-2'>
                  <div className='px-3.5 mb-0.5 flex items-center justify-between'>
                    <label className='text-xs text-gray-500 font-semibold'>{t("FORM.CV_INPUT.LABEL")}</label>
                  </div>
                  <div
                    className='flex items-center justify-between border rounded-lg px-3 py-2.5 text-sm w-full font-semibold text-[#5A5A5A]'>
                    <div className='flex gap-2 items-center'>
                      <FiFileText size={16}/>
                      <p>{user.profil?.cv && user.profil?.cv.originalFilename}</p>
                    </div>
                    <div className='flex gap-3 items-center'>
                      <FiEdit onClick={() => cvFileRef.current.click()} size={16}
                              className='cursor-pointer hover:text-brome'/>
                      <FiDownload onClick={() => downloadCvFile()} size={16}
                                  className='cursor-pointer hover:text-brome'/>
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  onChange={updateCv}
                  style={{display: 'none'}}
                  ref={cvFileRef}
                  id="fileUpload"
                  accept=".pdf,.doc,.docx"
                />
              </div>
        }
      </div>
      <ErrorModal isOpen={isErrorModalOpen} close={closeErrorModal} title={t(`ERRORS.${errorCode}`)}/>
    </div>
  )
}
