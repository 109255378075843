import axios from "axios";
import auth_API from "./api";
import { getCvFile } from "./accountService";
import { getUserCv } from "./userService";

export const getAllCandidatures = async (companyId)=>{
  try {
    const response = await auth_API.get(`${process.env.REACT_APP_API_URL}/candidatures/all`, {
      headers: {
        'XTENANT-HEADER': companyId
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the candidatures:', error);
    throw error;
  }
}

export const getCandidaturesPage = async (companyId, page, size, formData)=>{

  const params = {
    page: page,
    size: size,
    sort: `id,asc`,
  };

  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
    params,
  };

  try {
    const response = await auth_API.post(`${process.env.REACT_APP_API_URL}/candidatures/page`, formData, config);
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the candidatures:', error);
    throw error;
  }
}

export const createCandidature = async (companyId, jobId, storedFilename)=>{
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
    params: {
      annonceId: jobId,
    },
  };

  const formData = new FormData();

  await getUserCv(companyId).then((blob)=>{
    let mimeType = "application/pdf";
    if (storedFilename.endsWith(".docx")) {
      mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else if (storedFilename.endsWith(".doc")) {
      mimeType = "application/msword";
    }

    const fileToUpload = new File([blob], storedFilename, { type: mimeType });
    formData.append("pj", fileToUpload);
  });

  try {
    const response = await auth_API.post(`${process.env.REACT_APP_API_URL}/candidatures/create`, formData, config);
    return response.data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
}

export const createCandidatureCustomeCv = async (companyId, jobId, formData)=>{
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
    params: {
      annonceId: jobId,
    },
  };

  try {
    const response = await auth_API.post(`${process.env.REACT_APP_API_URL}/candidatures/create`, formData, config);
    return response.data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
}

export const createCandidatureAnonyme = async (companyId, formData) => {
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
      'Content-Type': 'multipart/form-data'
    }
  };
  
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/candidatures/anonyme`, formData, config);
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
};

export const cancelCandidature = async (companyId, id)=>{
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
  };
  
  try {
    const response = await auth_API.put(`${process.env.REACT_APP_API_URL}/candidatures/cancel/${id}`, {}, config);
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
}