import axios from "axios";
import auth_API from "./api";

export const getCvFile = async (fileName, companyId) => {
  if(fileName){
    const apiServerUrl = process.env.REACT_APP_DOWNLOAD_URL + '/download';
    const directory = "tenant/web_cv";

    const config = {
      headers: {
        'XTENANT-HEADER': companyId,
      },
    };

    try {
      const url = `${apiServerUrl}/${directory}/${fileName}`;
      const response = await auth_API.get(url, {
        responseType: 'blob',
      }, config);

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      return fileURL;
    } catch (error) {
      console.error('Download error:', error);
      // throw error;
    }
  }
};

export const accountCreate = async (companyId, formData) =>{

  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
      'Content-Type': 'multipart/form-data'
    },
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, formData, config);
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
}

export const accountLogin = async (companyId, data, recaptchaToken) =>{

  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
  };

  console.log(recaptchaToken);

  const body = {
    email: data.email, 
    password: data.password,
    recaptchaResponse: recaptchaToken
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/basic`, body, config);
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
};

export const accountUpdate = async (companyId, formData)=>{
  const config = {
    headers: {
      'XTENANT-HEADER': companyId,
    },
  };

  try {
    const response = await auth_API.put('/account/update', formData, config);
    return response.data;
  } catch (error) {
    console.error('There was an error:', error);
    throw error;
  }
}